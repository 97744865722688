/****** @MEDIAS *****/

/*** GRID VARIABLES ****/
// Basic container for all centered content
%content {
  width: 90%;
  margin: 0 auto;
  padding-left: $spacer;
  padding-right: $spacer;
  position: relative;
  @media #{$media-smaller} {
    width: 100%;
  }
  @media #{$media-iphone} {
    width: 97%;
  }
  @media #{$media-ipad} {
    width: 87%;
  }
  @media #{$media-desktop} {
    width: 960px;
  }
  @media #{$media-large} {
    width: 1200px;
  }
}

.contents {
  @extend %content;
}


/***** MEDIAS QUERIES *****/
@media #{$media-desktop} {
  .fixed-side{
    display: block;
  }
  #man {
    right: -318px;
  }
  #mobile-nav{
    display: none !important;
  }
  #search-bar{
    display: none !important;
  }
  .productsFoundResults{
    width: 70%;
  }
  #Refine-mobile{
    display: none !important;
  }
  .toogle-search{
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    display: block !important;
  }
}

@media #{$media-ipad} {
  .fixed-side{
    display: block;
  }
  embed{
    display: none;
  }
  #Categories .box{
    width: 32%;
    margin-right: 2%;
    &.last{
      margin-right: 0;
    }
    .img{
      max-height: 280px;
      overflow: hidden;
    }
  }
  #man{
    display: none;
  }

  #bottomFooter{
    text-align: center;
    .logo{
      float: none;
      margin-left: auto;
      margin-right: auto;
    }
    .menu-right{
      float: none;
      width: 100%;
    }
    #numTel{
      float: none;
    }
    #menuFooter{
      float: none;
    }
  }

  #Logo{
    display: none;
  }
  #mobile-logo{
    display: block;
  }
  #infosTitle{
    padding-top: 3.75em;
    font-size: 0.95em;
  }
  #Menu a{
    padding: 0 9px;
    font-size: 0.8em;
  }
  #mobile-nav{
    display: none !important;
  }
  #search-bar{
    display: none !important;
  }

  .shareProduct a{
    padding: 0 10px;
  }



  /*** Search filters **/

  .searchProducts{
    background-color: #F1F1F1;
  }

  .head-toggle .btn-toggle-menu-filters{
    display: block;
  }

  #menu-filters{
    display: none;
  }

  .searchProducts, .side-menu{
    width: 100%;
    padding: 2.5em 0.9em 1em;
    .refineSearch{
      margin: 0 -2%;
      padding: 0 2%;
    }
    .refineSearchTitle{
      //float: right;
    }
    .label-search{
      width: 30%;
      margin-left: 1.5%;
      margin-right: 1.5%;
      float: left;
      select{
        width: 100%;
      }
      &.small{
        width: 18%;
      }
      &.large{
        width: 48%;
      }
      &.xlarge{
        width: 75%;
      }
    }
    a.btnSearch{
      width: 48%;
      float: left;
    }
    a.btnReset{
      width: 48%;
      float: right;
    }
    a.btnSearch, a.btnReset{
      margin: 0;
    }
    .btnSearchProducts{
      margin: 4.5em 0 0;
    }
    #Refine-normal{
      display: none;
    }
    #Refine-mobile{
      display: block;
    }
  }

  .side-menu{
    width: 25%;
  }

  .productsFoundResults{
    width: 100%;
    margin: 0;
    &.second{
      width: 70%;
    }
  }

  #PageTitle .pageImportantInfo{
    font-size: 0.85em;
    width: 251px;
    height: 148px;
    img{
      margin-left: 11px;
      margin-top: 12px;
    }
  }

}

@media #{$media-ipad-mini} {
  .fixed-side{
    display: none;
  }
  embed, .obj-embed{
    display: none;
  }
  img.embed{
    display: block;
  }
  #Categories .box{
    width: 32%;
    margin-right: 2%;
    &.last{
      margin-right: 0;
    }
    .img{
      max-height: 280px;
      overflow: hidden;
    }
  }
  #man{
    display: none;
  }
  .backToSearch{
    display: none;
  }

  #bottomFooter{
    text-align: center;
    .logo{
      float: none;
      margin-left: auto;
      margin-right: auto;
    }
    .menu-right{
      float: none;
      width: 100%;
    }
    #numTel{
      float: none;
    }
    #menuFooter{
      float: none;
    }
  }

  // LOGO
  #Logo{
    display: none;
  }
  #mobile-logo{
    display: block;
  }
  // NAV
  #Menu a{
    padding: 0 9px;
    font-size: 0.8em;
  }
  #Menu{
    display: none;
  }
  #mobile-top, #mobile-top-phone{
    display: block;
  }
  #Categories .box{
    width: 100%;
    margin-bottom: 1em;
    h3{
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
    }
    .img{
      display: none;
    }
    .btn{
      display: none;
    }
    &:hover, &:focus{
      h3{
        background: #033176;
      }
    }
  }
  #infosTitle{
    width: 100%;
    text-align: center;
    font-size: 0.9em;
  }
  #quickSearchBox{
    display: none;
  }
  #dark{
    background: #222;
  }
  #Infos{
    background: #ededed; /* Old browsers */
    background: -moz-linear-gradient(top,  #ededed 0%, #FFF 67%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ededed), color-stop(67%,#FFF)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #ededed 0%,#FFF 67%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #ededed 0%,#FFF 67%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #ededed 0%,#FFF 67%); /* IE10+ */
    background: linear-gradient(to bottom,  #ededed 0%,#FFF 67%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#FFF',GradientType=0 ); /* IE6-9 */
  }
  #PageTitle{
    background: #ededed; /* Old browsers */
    background: -moz-linear-gradient(top,  #ededed 0%, #FFF 67%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ededed), color-stop(67%,#FFF)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #ededed 0%,#FFF 67%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #ededed 0%,#FFF 67%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #ededed 0%,#FFF 67%); /* IE10+ */
    background: linear-gradient(to bottom,  #ededed 0%,#FFF 67%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#FFF',GradientType=0 ); /* IE6-9 */
    height: inherit;
  }

  .shareProduct a{
    padding: 0 20px;
    p{ display: none;}
  }

  .sm-col-100{
    width: 100%;
    padding-right: 0;
  }

  #map_canvas{
    width: 99.5%;
    height: 180px;
    margin: 1.5em 0;
  }

  .btnSelling, .btnBuying, input[type="button"], input[type="submit"], .btnUpdate, .FormTable .FormBouton, #dark .btnList{
    font-size: 1em;
  }

  .FormTable{
    input.FormTextBox, input.FormTextBoxError{
      width: 100%;
      margin: 0;
    }
  }
  #dark{
    .Title{
      font-size: 2.1em;
    }
    .Text{
      font-size: 1.7em;
    }
  }

  /*** Search filters **/

  .head-toggle .btn-toggle-menu-filters{
    display: block;
  }

  #menu-filters{
    display: none;
  }

  .searchProducts{
    background: #F1F1F1;
    width: 100%;
    padding: 1.5em 1em;
    .refineSearch{
      margin: 0 -2%;
      padding: 0 2%;
    }
    .refineSearchTitle{
      //float: right;
    }
    .label-search{
      width: 30%;
      margin-left: 1.5%;
      margin-right: 1.5%;
      float: left;
      select{
        width: 100%;
      }
      &.small{
        width: 18%;
      }
      &.large{
        width: 48%;
      }
      &.xlarge{
        width: 75%;
      }
    }
    a.btnSearch{
      width: 48%;
      float: left;
    }
    a.btnReset{
      width: 48%;
      float: right;
    }
    a.btnSearch, a.btnReset{
      margin: 0;
    }
    .btnSearchProducts{
      margin: 4.5em 0 0;
    }
    #Refine-normal{
      display: none;
    }
    #Refine-mobile{
      display: block;
    }
  }

  .side-menu{
    // display: none;
  }

  .productsFoundResults.second{
    margin-top: 0;
  }

  .signup-btn{
    margin-top: 0;
    margin-bottom: 2em;
  }

  .productsFoundResults{
    width: 100%;
    margin: 0;
    table{
      font-size: 0.9em;
    }
  }

  #PageTitle .pageImportantInfo{
    display: none;
    font-size: 0.85em;
    width: 251px;
    height: 148px;
    img{
      margin-left: 11px;
      margin-top: 12px;
    }
  }

  .productFound .infoProductFound{
    width: 62%;
  }

  #Categories .box .label{
    top: -8px;
    right: -8px;
    width: 143px;
    height: 100px;
  }
}

@media #{$media-iphone} {
  .fixed-side{
    display: none;
  }
  embed, .obj-embed{
    display: none;
  }
  img.embed{
    display: block;
  }
  #man{
    display: none;
  }
  // Menu
  #Menu{
    display: none;
  }
  #mobile-top{
    display: block;
  }
  .backToSearch{
    display: none;
  }
  // LOGO
  #Logo{
    display: none;
  }
  #mobile-logo{
    display: block;
  }
  #Categories .box{
    width: 100%;
    margin-bottom: 1em;
    h3{
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
    }
    .img{
      display: none;
    }
    .btn{
      display: none;
    }
    &:hover, &:focus{
      h3{
        background: #033176;
      }
    }
  }
  #infosTitle{
    width: 100%;
    text-align: center;
    font-size: 0.8em;
  }
  #quickSearchBox{
    display: none;
  }
  #dark{
    background: #222;
  }
  #Infos{
    background: #ededed; /* Old browsers */
    background: -moz-linear-gradient(top,  #ededed 0%, #FFF 67%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ededed), color-stop(67%,#FFF)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #ededed 0%,#FFF 67%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #ededed 0%,#FFF 67%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #ededed 0%,#FFF 67%); /* IE10+ */
    background: linear-gradient(to bottom,  #ededed 0%,#FFF 67%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#FFF',GradientType=0 ); /* IE6-9 */
  }
  #PageTitle{
    background: #ededed; /* Old browsers */
    background: -moz-linear-gradient(top,  #ededed 0%, #FFF 67%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ededed), color-stop(67%,#FFF)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #ededed 0%,#FFF 67%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #ededed 0%,#FFF 67%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #ededed 0%,#FFF 67%); /* IE10+ */
    background: linear-gradient(to bottom,  #ededed 0%,#FFF 67%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#FFF',GradientType=0 ); /* IE6-9 */
    height: inherit;
  }
  #bottomFooter{
    text-align: center;
    height: inherit;
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(top,  #ffffff 0%, #ededed 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffffff), color-stop(100%,#ededed)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #ffffff 0%,#ededed 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #ffffff 0%,#ededed 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #ffffff 0%,#ededed 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #ffffff 0%,#ededed 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed',GradientType=0 ); /* IE6-9 */
    .menu-right{
      float: none;
      width: 100%;
      #numTel{
        float: none;
        margin: 1.5em 0;
        padding: 0;
      }
    }
    .logo{
      width: 160px;
      //display: none;
      float: none;
      margin-left: auto;
      margin-right: auto;
    }
    #copyrights{
      float: none;
    }
    #concepteurs{
      float: none;
      margin: 0;
      *{
        float: none;
        display: inline-block;
      }
    }
  }
  #MenuMobile{
    width: 40px;
    height: 22px;
  }
  #SearchMobile{
    width: 30px;
    height: 29px;
  }
  #mobile-logo{
    img{
      width: 150px;
      height: 47px;
      margin-bottom: .3em;
    }
  }
  .pageImportantInfo{
    display: none;
  }
  .product .detailsProductLeft, .product .detailsProductRight{
    width: 100%;
  }
  .product .detailsProductTitle{
    width: 50%;
  }
  #PageTitle h1{
    font-size: 2em !important;
    margin-top: 35px !important;
  }

  /*** Product fiche | Plant of the month + Used item ****/
  #PageInfos .product-fiche .img{
    width: 100%;
  }

  .descriptionProduct{
    width: 100%;
    margin-left: 0;
    border-bottom: 0;
  }

  .btnProduct{
    text-align: center;
    a.btnPpt{
      float: none;
      text-align: center;
      display: inline-block;
      margin: 0 7px 14px;
    }
  }

  /*** Sharing bar ******/
  .shareProduct{
    width: 100%;
    text-align: center;
    margin: 1.5em 0 0;
    padding: 2em 0 0;
    .productPhone{
      float: none;
      width: 100%;
      display: inline-block;
      margin: 0 auto;
    }
    .shareIcons{
      float: none;
      display: inline-block;
      margin: 15px auto 30px;
    }
  }

  .print{
    display: none;
  }

  .sm-col-100{
    width: 100%;
  }

  #map_canvas{
    width: 99.5%;
    height: 180px;
    margin: 1.5em 0;
  }
  .btnSelling, .btnBuying, input[type="button"], input[type="submit"], .btnUpdate, .FormTable .FormBouton, #dark .btnList{
    font-size: 1em;
  }

  .FormTable{
    input.FormTextBox, input.FormTextBoxError{
      width: 100%;
      margin: 0;
    }
  }
  #dark{
    .Title{
      font-size: 1.9em;
    }
    .Text{
      font-size: 1.5em;
    }
  }
  .col-50{
    width: 100%;
    &.sm-col-50{
      width: 50%;
    }
  }

  /*** Search filters **/

  .head-toggle .btn-toggle-menu-filters{
    display: block;
  }

  #menu-filters{
    display: none;
  }

  .searchProducts{
    background: #F1F1F1;
    width: 100%;
    padding: 1.5em 1em;
    .refineSearch{
      margin: 0 -2%;
      padding: 0 2%;
    }
    .refineSearchTitle{
      //float: right;
    }
    .label-search{
      width: 47%;
      margin-left: 1.5%;
      margin-right: 1.5%;
      float: left;
      &.right{
        float: right;
      }
      select{
        width: 100%;
      }
      &.small{
        width: 18%;
      }
      &.large{
        width: 48%;
      }
      &.xlarge{
        width: 63%;
      }
    }
    a.btnSearch{
      width: 48%;
      float: left;
    }
    a.btnReset{
      width: 48%;
      float: right;
    }
    a.btnSearch, a.btnReset{
      margin: 0;
    }
    .btnSearchProducts{
      margin: 4.5em 0 0;
    }
    #Refine-normal{
      display: none;
    }
    #Refine-mobile{
      display: block;
    }
  }

  .side-menu{
    // display: none;
  }

  .productsFoundResults.second{
    margin-top: 0;
  }

  .signup-btn{
    margin-top: 0;
    margin-bottom: 2em;
  }

  .productsFoundResults{
    width: 100%;
    margin: 0;
    table{
      font-size: 0.85em;
    }
  }

  #PageTitle .pageImportantInfo{
    display: none;
    font-size: 0.85em;
    width: 251px;
    height: 148px;
    img{
      margin-left: 11px;
      margin-top: 12px;
    }
  }

  .productFound{
    .img{
      width: 50%;
      margin-bottom: 2em;
    }
    .infoProductFound{
      width: 100%;
      margin: 0;
    }
  }

  .productFound{
    .btnProductFound{
      text-align: center;
      float: left;
      a{
        display: inline-block;
        float: none;
        margin: 0 1em 0 0;
      }
    }
  }

  #BusinessQuote{
    background: #04409a;
    .title{
      padding: 1em;
    }
  }

  #Categories .box .label{
    top: -8px;
    right: -8px;
    width: 143px;
    height: 100px;
  }
}

@media #{$media-smaller} {
  .fixed-side{
    display: none;
  }

  embed, .obj-embed{
    display: none;
  }
  img.embed{
    display: block;
  }
  #BusinessQuote{
    background: #04409a;
    .title{
      padding: 1.6em;
    }
  }
  #man{
    display: none;
  }
  #Menu{
    display: none;
  }
  #mobile-top{
    display: block;
  }
  .backToSearch{
    display: none;
  }
  // LOGO
  #Logo{
    display: none;
  }
  #mobile-logo{
    display: block;
  }
  #Categories .box{
    width: 100%;
    margin-bottom: 1em;
    h3{
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      padding: 0.7em;
    }
    .img{
      display: none;
    }
    .btn{
      display: none;
    }
    &:hover, &:focus{
      h3{
        background: #033176;
      }
    }
  }
  #infosTitle{
    width: 100%;
    text-align: center;
    font-size: 0.7em;
  }
  #quickSearchBox{
    display: none;
  }
  #dark{
    background: #222;
  }
  #Infos{
    background: #ededed; /* Old browsers */
    background: -moz-linear-gradient(top,  #ededed 0%, #FFF 67%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ededed), color-stop(67%,#FFF)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #ededed 0%,#FFF 67%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #ededed 0%,#FFF 67%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #ededed 0%,#FFF 67%); /* IE10+ */
    background: linear-gradient(to bottom,  #ededed 0%,#FFF 67%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#FFF',GradientType=0 ); /* IE6-9 */
  }
  #PageTitle{
    background: #ededed; /* Old browsers */
    background: -moz-linear-gradient(top,  #ededed 0%, #FFF 67%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ededed), color-stop(67%,#FFF)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #ededed 0%,#FFF 67%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #ededed 0%,#FFF 67%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #ededed 0%,#FFF 67%); /* IE10+ */
    background: linear-gradient(to bottom,  #ededed 0%,#FFF 67%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#FFF',GradientType=0 ); /* IE6-9 */
    height: inherit;
  }
  #bottomFooter{
    text-align: center;
    height: inherit;
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(top,  #ffffff 0%, #ededed 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffffff), color-stop(100%,#ededed)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #ffffff 0%,#ededed 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #ffffff 0%,#ededed 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #ffffff 0%,#ededed 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #ffffff 0%,#ededed 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed',GradientType=0 ); /* IE6-9 */
    .menu-right{
      float: none;
      width: 100%;
      #numTel{
        float: none;
        margin: 1.5em 0;
        padding: 0;
      }
    }
    .logo{
      //display: none;
      float: none;
      margin-left: auto;
      margin-right: auto;
      width: 140px;
    }
    #copyrights{
      float: none;
    }
    #concepteurs{
      float: none;
      margin: 0;
      *{
        float: none;
        display: inline-block;
      }
    }
  }
  #MenuMobile{
    width: 40px;
    height: 22px;
  }
  #SearchMobile{
    width: 29px;
    height: 29px;
  }
  #mobile-logo{
    img{
      /*width: 150px;
      height: 47px;
      margin-bottom: .3em;*/
      width: 135px;
      height: 40px;
      margin-bottom: .5em;
      margin-top: .25em;
    }
  }
  .pageImportantInfo{
    display: none;
  }
  .product .detailsProductLeft, .product .detailsProductRight{
    width: 100%;
  }
  .product .detailsProductTitle{
    width: 50%;
  }
  #PageTitle h1{
    font-size: 1.4em;
    margin-top: 1em;
    font-weight: 700;
  }
  #search-bar input{
    &[type="search"], &[type="text"]{
      margin-left: 1em;
    }
    &[type="button"], &[type="submit"]{
      margin-right: 1em;
    }
  }

  /*** Product fiche | Plant of the month + Used item ****/
  #PageInfos .product-fiche .img{
    width: 100%;
  }

  .descriptionProduct{
    width: 100%;
    margin-left: 0;
    border-bottom: 0;
  }

  .btnProduct{
    text-align: center;
    a.btnPpt{
      float: none;
      text-align: center;
      display: inline-block;
      margin: 0 7px 14px;
    }
  }

  /*** Sharing bar ******/
  .shareProduct{
    width: 100%;
    text-align: center;
    margin: 1.5em 0 0;
    padding: 2em 0 0;
    .productPhone{
      float: none;
      width: 100%;
      display: inline-block;
      margin: 0 auto;
    }
    .shareIcons{
      float: none;
      display: inline-block;
      margin: 15px auto 30px;
    }
    a{
      padding: 0 15px;
      p{
        font-size: 1em;
      }
    }
  }

  .print{
    display: none;
  }

  .sm-col-100{
    width: 100%;
  }

  #map_canvas{
    width: 99.5%;
    height: 180px;
    margin: 1.5em 0;
  }

  .btnSelling, .btnBuying, input[type="button"], input[type="submit"], .btnUpdate, .FormTable .FormBouton, #dark .btnList{
    font-size: 0.9em;
  }

  .FormTable{
    input.FormTextBox, input.FormTextBoxError{
      width: 100%;
      margin: 0;
    }
  }
  #dark{
    .Title{
      font-size: 1.7em;
    }
    .Text{
      font-size: 1.3em;
    }
  }
  .col-50{
    width: 100%;
    &.col-xsm-50{
      width: 50%;
    }
  }
  .row{
    margin-left: -$spacer;
    margin-right: -$spacer;
  }

  /*** Search filters **/

  .head-toggle .btn-toggle-menu-filters{
    display: block;
  }

  #menu-filters{
    display: none;
  }

  .searchProducts{
    background: #F1F1F1;
    width: 100%;
    padding: 0.5em 1.2em 0.8em;
    margin-bottom: 1.25em;
    .refineSearch{
      margin: 0 -2%;
      padding: 0 2%;
      p{
        margin: 0.85em 0 0.25em;
      }
    }
    .refineSearchTitle{
      //float: right;
    }
    .label-search{
      width: 98.5%;
      margin-left: 1.5%;
      margin-right: 1.5%;
      float: left;
      margin-bottom: 0.5em;
      &.title{
        //margin: 1.1em 0;
        margin: 1em 0 0.5em;
      }
      select{
        width: 100%;
      }
      &.small{
        width: 98.5%;
      }
      &.large{
        width: 48%;
      }
      &.xlarge{
        width: 97.5%;
        float: right;
      }
    }
    a.btnSearch{
      width: 48%;
      float: left;
    }
    a.btnReset{
      width: 48%;
      float: right;
    }
    a.btnSearch, a.btnReset{
      margin: 0;
    }
    .btnSearchProducts{
      margin: 0.5em 0 0;
    }
    #Refine-normal{
      display: none;
    }
    #Refine-mobile{
      display: block;
    }
  }

  .side-menu{
    // display: none;
  }
  .productsFoundResults.second{
    margin-top: 0;
  }

  .signup-btn{
    margin-top: 0;
    margin-bottom: 1.5em;
    display: none;
  }

  .productsFoundResults{
    width: 100%;
    margin: 0;
    table{
      font-size: 0.85em;
    }
  }

  #PageTitle .pageImportantInfo{
    display: none;
    font-size: 0.85em;
    width: 251px;
    height: 148px;
    img{
      margin-left: 11px;
      margin-top: 12px;
    }
  }

  .productFound{
    .img{
      margin-left: 0;
      width: 100%;
    }
    .infoProductFound{
      margin-left: 0;
      margin-top: 2em;
      width: 100%;
    }
  }

  .selectPage {
    display: none;
    /*float: right;
    select{
      font-size: 80%;
      max-width: 40px;
    }*/
  }

  .numberPage{
    float: none;
    text-align: center;
    margin: 1.25em 0 0;
    a{
      width: 28px;
      height: 28px;
      line-height: 26.5px;
      font-size: 0.85em;
    }
  }

  #mobile-nav{
    ul{
      font-size: 0.9em;
    }
  }

  .productsFoundResults.second{
    h2{font-size: 1.75em;}
    h3{font-size: 1.5em;}
  }

  .productFound{
    .btnProductFound{
      text-align: center;
      float: none;
      a{
        display: inline-block;
        float: none;
        margin: 0.5em;
        font-size: 0.75em;
      }
    }
  }

  #Categories .box .label{
    top: -6px;
    right: -6px;
    width: 113px;
    height: 79px;
  }

  /** Home page box slider **/
  .currentBestDeals .bxslider > li {
    .img{
      width: 100%;
      text-align: center;
      margin-bottom: 1em;
      img{
        float: none;
      }
    }
    .info{
      width: 100%;
      margin: 0;
      font-size: 14px;
    }
  }

}

@media #{$media-large} {
  .fixed-side{
    display: block;
  }
  #Menu a{
    padding: 0px 16px 0px 16px;
  }
  #man{
    right: -258px;
  }
  #mobile-nav{
    display: none !important;
  }
  #search-bar{
    display: none !important;
  }
  .productsFoundResults{
    width: 76.5%;
  }
  #Refine-mobile{
    display: none !important;
  }
  .toogle-search{
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    display: block !important;
  }
}
