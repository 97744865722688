#mycarousel{ 
	width:100%;
	left:-36px;
}

.jcarousel-skin-tango .jcarousel-container {
}

.jcarousel-skin-tango .jcarousel-direction-rtl {
	direction: rtl;
}

.jcarousel-skin-tango .jcarousel-container-horizontal {
	width:100%;
	height:65px;
}

.jcarousel-skin-tango .jcarousel-container-vertical {
    width:100%;
    height:360px;
    padding:0px;
}


.jcarousel-skin-tango .jcarousel-clip {
    overflow: hidden;
}

.jcarousel-skin-tango .jcarousel-clip-horizontal {
    width:  100%;
    height:65px;
}

.jcarousel-skin-tango .jcarousel-clip-vertical {
    width: 100%;
    height:365px;
}

.jcarousel-skin-tango .jcarousel-item {
    width:100px;
    height:100%;
}

.jcarousel-skin-tango #Second-carousel .jcarousel-item { 
	height:140px;
}

 
.jcarousel-skin-tango .jcarousel-item #Second-carousel{
    width:256px;
    height:150px;
}



.jcarousel-skin-tango .jcarousel-item-horizontal {
	margin-left:0;
    margin-right:15px;
}

.jcarousel-skin-tango .jcarousel-direction-rtl .jcarousel-item-horizontal {
	margin-left: 10px;
    margin-right: 0;
}

.jcarousel-skin-tango .jcarousel-item-vertical {
    margin-bottom: 10px;
}

.jcarousel-skin-tango .jcarousel-item-placeholder {
    background: #fff;
    color: #000;
}

/***  Horizontal Buttons*/
.jcarousel-skin-tango .jcarousel-next-horizontal {
	position:absolute;
	display: block;
	width: 19px;
	height: 29px;
	background: url("/Design/Images/Icon/DroiteFleche.png") no-repeat;
	cursor: pointer;
/*	transition: all 0.4s;
	-moz-transition: all 0.4s;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;*/
}

.jcarousel-skin-tango .jcarousel-prev-horizontal{
	position:absolute;
	display: block;
	width: 19px;
	height: 29px;
	background: url("/Design/Images/Icon/DroiteFleche.png") no-repeat;
	cursor: pointer;
/*	transition: all 0.4s;
	-moz-transition: all 0.4s;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;*/
}

.jcarousel-skin-tango .jcarousel-next-horizontal{ background-position:-26px 0; top:-104px; right:0px; }
.jcarousel-skin-tango .jcarousel-next-horizontal:hover, .jcarousel-skin-tango .jcarousel-next-horizontal:focus{ background-position:-26px -39px;}

.jcarousel-skin-tango .jcarousel-prev-horizontal{ background-position: 0 0; top: 71px; right: 5px; }
.jcarousel-skin-tango .jcarousel-prev-horizontal:hover, .jcarousel-skin-tango .jcarousel-prev-horizontal:focus{ background-position:0 -29px;}

.jcarousel-skin-tango .jcarousel-next-disabled-horizontal,
.jcarousel-skin-tango .jcarousel-next-disabled-horizontal:hover,
.jcarousel-skin-tango .jcarousel-next-disabled-horizontal:focus,
.jcarousel-skin-tango .jcarousel-next-disabled-horizontal:active {
    cursor: default;
    background-position:-26px -39px !important;
}

.jcarousel-skin-tango .jcarousel-prev-disabled-horizontal,
.jcarousel-skin-tango .jcarousel-prev-disabled-horizontal:hover,
.jcarousel-skin-tango .jcarousel-prev-disabled-horizontal:focus,
.jcarousel-skin-tango .jcarousel-prev-disabled-horizontal:active {
	cursor:default;
	background-position:0 -39px !important;
	
}


/***  Vertical Buttons */
 
#News .navigation .navTop, #News .navigation .navBottom{}
#News .navigation .navTop{ background-position: 0 0;}
#News .navigation .navBottom{ background-position:0 -17px;}
#News .navigation .navTop:hover, #News .navigation .navTop:focus, #News .navigation .navBottom:hover, #News .navigation .navBottom:focus{
-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
filter: alpha(opacity=100);
opacity: 1;}
/**/
.jcarousel-skin-tango .jcarousel-next-vertical {
    position: absolute;
	top: -47px;
	right: 12px;
    cursor: pointer;
	z-index:3000;
	width: 26px;
	height: 17px;
	display: block;
	margin: 0 0 14px;
	float: left;
	background: url("/Design/Icons/arrows_News_26x17.png") 0 -17px no-repeat;
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	filter: alpha(opacity=50);
	opacity: 0.5;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	-ms-transition: all 0.4s;
	transition: all 0.4s;
}
.jcarousel-skin-tango .jcarousel-next-vertical:hover, .jcarousel-skin-tango .jcarousel-next-vertical:focus { background-position: 0 -17px;}
.jcarousel-skin-tango .jcarousel-next-vertical:active {/*background-position: 0 -64px;*/}

.jcarousel-skin-tango .jcarousel-prev-vertical {
    position: absolute;
    top: -78px;
    right: 12px;
    cursor: pointer;
	z-index:3000;
	width: 26px;
	height: 17px;
	display: block;
	margin: 0 0 14px;
	float: left;
	background: url("/Design/Icons/arrows_News_26x17.png") 0 0 no-repeat;
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	filter: alpha(opacity=50);
	opacity: 0.5;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	-ms-transition: all 0.4s;
	transition: all 0.4s;

}

.jcarousel-skin-tango .jcarousel-prev-vertical:hover,
.jcarousel-skin-tango .jcarousel-prev-vertical:focus { background-position: 0 0;}
.jcarousel-skin-tango .jcarousel-prev-vertical:active {}

.jcarousel-skin-tango .jcarousel-prev-disabled-vertical,
.jcarousel-skin-tango .jcarousel-prev-disabled-vertical:hover,
.jcarousel-skin-tango .jcarousel-prev-disabled-vertical:focus,
.jcarousel-skin-tango .jcarousel-prev-disabled-vertical:active {
}

