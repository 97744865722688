/***************************************************************************************
 * File:  Layout.css
 * Author:  Anekdotes Communications
 ***************************************************************************************/
*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

::selection {
  background: #a8d1ff;
  color: #fff; }

::-moz-selection {
  background: #a8d1ff;
  color: #fff; }

::-webkit-selection {
  background: #a8d1ff;
  color: #fff; }

/***************************************************************************************
 *  @FONTFACE
 ***************************************************************************************/
/***************************************************************************************
 *  Formattage General
 ***************************************************************************************/
html {
  font-size: 100%;
  width: 100%;
  height: 100%; }

body {
  padding: 0px;
  margin: 0px; }

form {
  padding: 0px;
  margin: 0px; }

img {
  vertical-align: middle; }

.csstransitions a {
  transition: color 0.4s;
  -moz-transition: color 0.4s;
  /* Firefox 4 */
  -webkit-transition: color 0.4s;
  /* Safari and Chrome */
  -o-transition: color 0.4s;
  /* Opera */ }

.csstransitions input {
  transition: all 0.4s;
  -moz-transition: all 0.4s;
  /* Firefox 4 */
  -webkit-transition: all 0.4s;
  /* Safari and Chrome */
  -o-transition: all 0.4s;
  /* Opera */ }

a:link, a:visited {
  color: #0066cc; }

a:hover, a:active {
  color: #950000;
  text-decoration: none; }

img {
  border: none; }

* {
  margin: 0;
  padding: 0; }

input:focus {
  outline: none; }

.Clear {
  clear: both; }

.Containers {
  width: 991px;
  margin: 0 auto;
  padding: 0;
  position: relative; }

.Contents {
  width: 967px;
  margin: 0 auto;
  padding: 0 12px 0 12px;
  position: relative; }

hr {
  border-left: none;
  border-right: none;
  height: 0;
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #CCC;
  display: block; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-style: normal;
  color: #232e3e;
  text-shadow: 0 1px #FFF; }

body {
  background: #FFF;
  font-family: Tahoma, Arial, Helvetica, sans-serif; }

#Page {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }

p {
  font-size: 1em;
  color: #68696b;
  line-height: 24px;
  padding-bottom: 1.5em; }

.AlignCenter {
  text-align: center; }

.NoTopSpace {
  margin-top: 0px !important;
  padding-top: 0px !important; }

.NoBottomSpace {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important; }

.NoSpace {
  margin: 0px !important;
  padding: 0px !important; }

.NoBg {
  background: none !important; }

.PullLeft {
  float: left; }

.PullRight {
  float: right; }

.jcarousel-skin-tango img {
  cursor: pointer; }

/* Color classes ______________________________________________________________________*/
.Pink {
  color: #EC008C; }

/* Buttons ____________________________________________________________________________*/
/***************************************************************************************/
/*  H E A D E R                                                                        */
/***************************************************************************************/
#Header {
  background: url("/Design/Images/backgrounds/bgHeader.png") repeat-x #0445a6;
  height: 90px; }

#Logo {
  float: left;
  position: absolute;
  left: 15px; }

#mobile-logo {
  float: left;
  display: none;
  padding: 0.5em 0; }

#Menu {
  float: right; }

#Menu li {
  height: 76px;
  display: block;
  float: left;
  line-height: 76px; }

#Menu a {
  height: 76px;
  color: #fff;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-decoration: none;
  display: block;
  padding: 0px 9px;
  background: url("/Design/Images/backgrounds/bgMenuLink.png") no-repeat top right;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

#Menu a:hover {
  color: #6ea8ff; }

#mobile-top {
  display: none;
  float: right;
  margin-top: 1.5em; }

  #mobile-top-phone {
    display: none;
    position: absolute;
    right: 12px;
    font-weight: bold;
    text-decoration:none;
    color: #eaa313;
    font-size: 16px;
    font-family: Tahoma, Arial, Helvetica, sans-serif;
  }

.icon-mobile {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s; }
  .icon-mobile:hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.50;
    -khtml-opacity: 0.50;
    opacity: 0.50; }

#MenuMobile {
  float: left;
  width: 44px;
  height: 27px;
  background-image: url("/Design/Images/Icon/icon-menu-mobile.png");
  margin-left: 1.2em; }

#SearchMobile {
  position: relative;
  top: -6px;
  float: left;
  width: 36px;
  height: 36px;
  background-image: url("/Design/Images/Icon/icon-search-mobile.png");
  margin-left: 1.2em; }

#RequestBtn {
  position: relative;
  top: -9px;
  float: left;
  width: 43px;
  height: 35px;
  background-image: url("/Design/Images/Icon/request-icon@2x.png"); }

#mobile-nav, #search-bar {
  display: none;
  background: #04429f; }
  #mobile-nav ul, #search-bar ul {
    margin: 0 auto;
    padding: 0;
    list-style: none; }
    #mobile-nav ul ul, #search-bar ul ul {
      padding-left: 2.5em;
      display: none; }
    #mobile-nav ul li .dd-menu, #search-bar ul li .dd-menu {
      position: relative;
      display: block; }
      #mobile-nav ul li .dd-menu i, #search-bar ul li .dd-menu i {
        position: absolute;
        cursor: pointer;
        top: 50%;
        margin-top: -12px;
        right: 1em;
        display: block;
        width: 24px;
        height: 24px;
        background: url("/Design/Images/Icon/icon-plus-light.png");
        background-size: 100% 100%; }
    #mobile-nav ul li a, #search-bar ul li a {
      display: block;
      color: #FFF;
      text-decoration: none;
      font-size: 1.3em;
      padding: 0.5em 1em;
      border-bottom: 1px solid #002864;
      -webkit-transition: background 0.3s;
      -moz-transition: background 0.3s;
      -ms-transition: background 0.3s;
      -o-transition: background 0.3s;
      transition: background 0.3s; }
      #mobile-nav ul li a:hover, #mobile-nav ul li a:focus, #search-bar ul li a:hover, #search-bar ul li a:focus {
        background: #002864; }
    #mobile-nav ul li.active > ul, #search-bar ul li.active > ul {
      display: block; }
      #mobile-nav ul li.active > ul li.active > ul, #search-bar ul li.active > ul li.active > ul {
        display: block; }
      #mobile-nav ul li.active > ul li.active > .dd-menu i, #search-bar ul li.active > ul li.active > .dd-menu i {
        display: block;
        width: 24px;
        height: 24px;
        background: url("/Design/Images/Icon/icon-minus-light.png");
        background-size: 100% 100%; }
    #mobile-nav ul li.active > .dd-menu i, #search-bar ul li.active > .dd-menu i {
      display: block;
      width: 24px;
      height: 24px;
      background: url("/Design/Images/Icon/icon-minus-light.png");
      background-size: 100% 100%; }
  #mobile-nav input, #search-bar input {
    width: 55%;
    padding: 0.75em 1.25em;
    margin: 1em 0 1em 2em;
    border: 0;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    font-size: 1.05em;
    -webkit-appearance: none;
    appearance: none;
    /*&[type="button"],&[type="submit"]{
      float: right;
      cursor: pointer;
      width: 24%;
      max-width: 120px;
      margin: 1em 2em 1em 0;
      background: #fda900;
      color: #FFF;
      font-weight: bold;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      &:hover, &:focus{
        background: #fd7100;
      }
    }*/ }
  #mobile-nav a#QuickForm, #search-bar a#QuickForm {
    float: right;
    cursor: pointer;
    width: 24%;
    max-width: 120px;
    margin: 1em 2em 1em 0;
    background: #fda900;
    color: #FFF;
    font-weight: bold;
    padding: 1em 1.25em;
    border: 0;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    font-size: 1.05em;
    -webkit-appearance: none;
    appearance: none;
    text-decoration: none;
    line-height: 0.7em;
    text-align: center;
    text-transform: uppercase;
    position: static;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: inherit;
    height: initial;
    -webkit-transition: background 0.3s;
    -moz-transition: background 0.3s;
    -ms-transition: background 0.3s;
    -o-transition: background 0.3s;
    transition: background 0.3s; }
    #mobile-nav a#QuickForm:hover, #mobile-nav a#QuickForm:focus, #search-bar a#QuickForm:hover, #search-bar a#QuickForm:focus {
      background: #fd7100; }

#Header .activeMenu {
  color: #6ea8ff; }

/***************************************************************************************/
/*  S T A G E                                                                          */
/***************************************************************************************/
.swipe {
  overflow: hidden;
  visibility: hidden;
  position: relative; }

.swipe-wrap {
  overflow: hidden;
  position: relative;
  width: 100% !important; }

.swipe-wrap > div {
  float: left;
  width: 100%;
  position: relative; }

.simple-slider {
  overflow: hidden;
  visibility: hidden;
  position: relative; }

.simple-slider .slide-left, .simple-slider .slide-right {
  opacity: 0.6;
  position: absolute;
  top: 115px;
  height: 37px;
  width: 35px;
  cursor: pointer;
  z-index: 1; }

.simple-slider .slide-left {
  background: url(/Design/Images/buttons/btnPrevProduct.png) center center no-repeat;
  left: 0px; }

.simple-slider .slide-right {
  background: url(/Design/Images/buttons/btnNextProduct.png) center center no-repeat;
  right: 0px; }

.simple-slider.simple-slider-with-nav .simple-slider-wrap {
  margin: 0 auto; }

.simple-slider .simple-slider-wrap {
  overflow: hidden;
  position: relative; }

.simple-slider .simple-slider-wrap > div {
  float: left;
  width: 100%;
  position: relative; }

.slider-thumbs {
  margin-left: 10px;
  padding-top: 10px;
  width: 460px; }

.slide-left:hover {
  opacity: 1; }

.slide-right:hover {
  opacity: 1; }

.slider-thumbs img {
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.4s;
  padding-bottom: 5px;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s; }

.slider-thumbs img:hover {
  cursor: pointer;
  opacity: 1;
  transition: all 0.4s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.4s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s; }

/***************************************************************************************/
/*  C O N T E N T                                                                      */
/***************************************************************************************/
/****Infos****/
#Infos {
  background: url("/Design/Images/backgrounds/bg-concrete.jpg") center top #f3f3f3; }

#infosTitle {
  color: #212121;
  padding-top: 3em;
  float: left;
  width: 65%; }
  #infosTitle h2 {
    line-height: 1.1em;
    font-size: 22px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    margin: 0 0 0.5em; }

#infosTitle h1, #infosTitle h2 {
  color: #212121;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  line-height: 0.9em;
  margin: 0 0 0.5em; }
#infosTitle h1 {
  font-size: 2.4em; }
#infosTitle h2 {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 1.05em; }

.languages {
  text-align: center; }
  .languages a {
    padding-top: 0.5em;
    color: #9b9b9b;
    font-size: 0.8em;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700; }
    .languages a.hover, .languages a:hover, .languages a:focus {
      color: #0066cc; }

.selectPage {
  padding-top: 20px; }

.selectPage p {
  margin-top: 4px;
  float: left;
  padding-right: 10px; }

.selectPage select {
  float: left;
  max-width: 66px;
  margin-top: -5px; }

.numberPage {
  float: right; }
  .numberPage a {
    color: #9b9b9b;
    font-size: 0.95em;
    width: 30px;
    height: 30px;
    text-decoration: none;
    text-align: center;
    line-height: 28px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    display: inline-block;
    padding: 0 3px;
    border: 1px solid #9b9b9b;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s; }
    .numberPage a:hover, .numberPage a:focus {
      border-color: #0051B8;
      color: #0051B8; }
    .numberPage a.first-child, .numberPage a.last-child {
      font-weight: 900; }
    .numberPage a.currentPage {
      border-color: #0051B8;
      background: #0051B8;
      color: #FFF; }
    .numberPage a.disabled {
      border-color: #DBDBDB;
      color: #DBDBDB; }

/****Quick search****/
#quickSearchBox {
  position: relative;
  float: right;
  width: 30%;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
  transition: width 0.3s; }
  #quickSearchBox .shadow {
    display: block;
    width: 7px;
    height: 136px;
    position: absolute;
    top: 0; }
    #quickSearchBox .shadow.left {
      left: -8px;
      background: url("/Design/Images/others/searchLeftShadow.png") no-repeat; }
    #quickSearchBox .shadow.right {
      right: -8px;
      background: url("/Design/Images/others/searchRightShadow.png") no-repeat; }

#quickSearch a {
  text-decoration: none; }

#quickSearch {
  width: 100%;
  background: url("/Design/Images/backgrounds/bg-search.png") no-repeat center center;
  background-size: 100% 100%;
  float: left;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  position: relative; }

#quickSearchTitle {
  margin-top: 24px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  padding-bottom: 22px !important; }

#quickSearch a {
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px; }

#quickSearchUsed {
  margin-left: 22px; }

#quickSearchNew {
  margin-left: 12px; }

.quickSearch:hover {
  color: #000; }

.quickSearch.selected {
  font-weight: 700;
  color: #0053d0; }

#quickSearchAdvanced {
  margin-left: 61px;
  font-weight: 600;
  color: #0445a6; }

#quickSearchAdvanced:hover {
  color: #0445a6 !important;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

#quickSearch input[type="text"] {
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #7388a7;
  padding: 10px;
  outline: none !important;
  border: 1px solid #CCC;
  background: #fff;
  background-repeat: no-repeat;
  background-position: right;
  box-shadow: 0 0 0 rgba(0, 0, 255, 0);
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 255, 0);
  -moz-box-shadow: 0 0 0 rgba(0, 0, 255, 0);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

#QuickSearchContainer {
  position: relative;
  background: #FFF;
  width: 90%;
  margin: 10px auto 0;
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

#QuickSearchContainer.FocusOne {
  box-shadow: 0 0 10px #1a96ff;
  -webkit-box-shadow: 0 0 10px #1a96ff;
  -moz-box-shadow: 0 0 10px #1a96ff; }

#QuickForm {
  background: url("/Design/Images/buttons/btnQuickSearch.png") #FFF;
  background-repeat: no-repeat;
  background-position: center center;
  border: none;
  border-left: 1px solid #CCC;
  position: absolute;
  right: 1px;
  top: 1px;
  width: 39px;
  height: 39px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out; }

#QuickForm:hover, #QuickForm:focus {
  background: url("/Design/Images/buttons/btnQuickSearch.png") #EEE;
  background-repeat: no-repeat;
  background-position: center center; }

/****Categories****/
#Categories {
  margin: 35px auto; }

#Categories .box {
  position: relative;
  margin-right: 20px;
  float: left;
  display: block;
  text-decoration: none;
  width: calc(33.333% - 20px);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
  #Categories .box:hover .img, #Categories .box:focus .img {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    -moz-opacity: 0.8;
    -khtml-opacity: 0.8;
    opacity: 0.8; }
  #Categories .box:hover .btn, #Categories .box:focus .btn {
    background: #033176; }

#Categories .box img.main, #Categories .box .reflection {
  display: block;
  width: 100%; }
  #Categories .box img.main img, #Categories .box .reflection img {
    width: 100%; }

.box .reflection {
  display: block;
  position: absolute;
  bottom: -35px;
  left: 0; }
  .box .reflection img {
    width: 100%; }

#Categories .box .btn {
  display: block;
  position: absolute;
  bottom: 15px;
  left: 5%;
  width: 80%;
  padding: 20px 5%;
  color: #FFF;
  font-size: 1.25em;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  text-decoration: none; }

#Categories .box .label {
  position: absolute;
  z-index: 10;
  top: -10px;
  right: -10px;
  display: block;
  width: 177px;
  height: 123.5px;
  background: url("/Design/Images/Layout/label-hot-item@2x.png") no-repeat;
  background-size: 100% 100%; }

#Categories h3 {
  background: #212121;
  color: #fff;
  padding: 1.25em 0 1.25em 1em;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 1.2em; }

.categoriesTitleLight {
  font-weight: normal !important; }

#Categories .img {
  background: #FFF;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
  #Categories .img img {
    width: 100%; }

/*** BTN *****/
.btn {
  display: block;
  text-align: center;
  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  -ms-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s; }
  .btn.dark {
    background: #222;
    background: rgba(0, 0, 0, 0.8); }
    .btn.dark:hover, .btn.dark:focus {
      background: #033176; }

i.icon {
  display: inline-block; }
  i.icon.arrow-white {
    width: 11px;
    height: 15px;
    margin-left: 5px;
    background: url("/Design/Images/Icon/arrow-white.png") no-repeat; }

.btnCategories {
  position: absolute;
  margin-left: 26px;
  bottom: 55px; }

.btnSeeUsedEquipment
.btnSeeNewEquipment
.btnSeePlant {
  left: 0;
  bottom: 20px; }

#categoriesHotItem {
  position: absolute;
  right: -10px;
  top: -7px;
  z-index: 2; }

/****Buyers Sellers****/
#BuyersSellers {
  /*top:36px;*/
  padding: 40px 0 50px;
  position: relative;
  z-index: 5; }

#BuyersSellers h2 {
  color: #000;
  font-size: 32px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600; }

#BuyersSellers h3 {
  color: #000;
  font-size: 23px;
  margin-bottom: 32px;
  font-family: 'Open Sans', sans-serif; }

/****Man****/
#man {
  position: absolute;
  bottom: 0;
  right: -108px;
  z-index: 3;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

.btnBuying {
  background-color: #eaa313 !important;
  background: url(/Design/Images/buttons/btnBuying.jpg) no-repeat right;
  display: inline-block;
  height: 61px !important;
  padding-left: 20px;
  padding-right: 48px;
  line-height: 61px;
  color: #fff !important;
  font-size: 1.25em;
  text-decoration: none;
  float: left;
  margin-right: 18px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  margin-bottom: 1em;
  border: 0; }

.btnBuying:hover {
  background-color: #fdc003 !important;
  background: url(/Design/Images/buttons/btnBuyingOver.jpg) no-repeat right; }

.btnSelling {
  background-color: #0445a6 !important;
  background: url(/Design/Images/buttons/btnSelling.jpg) no-repeat right;
  display: inline-block;
  height: 61px !important;
  padding-left: 20px;
  padding-right: 48px;
  line-height: 61px;
  color: #fff !important;
  font-size: 1.25em;
  text-decoration: none;
  float: left;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700; }

.btnUpdate {
  background-color: #0445a6 !important;
  background: url("/Design/Images/buttons/btnSelling.jpg") no-repeat right;
  display: inline-block;
  padding: 0 37px 0 17px;
  line-height: 40px;
  height: 40px;
  color: #fff !important;
  font-size: 14px;
  text-decoration: none;
  float: left;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700; }

.btnUpdate:hover {
  background-color: #125ece !important;
  background: url(/Design/Images/buttons/BtnSellingOver.jpg) no-repeat right; }

.btnSelling:hover {
  background-color: #125ece !important;
  background: url(/Design/Images/buttons/BtnSellingOver.jpg) no-repeat right; }

.BuyingForm .info {
  /*width:310px;
  float:left;*/
  padding: 1em 0 0; }

.btnSelling, .btnUpdate, .btnBuying, input[type="button"], input[type="submit"] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.signup-btn {
  float: right;
  margin-top: 45px; }

.DeleteProduct {
  float: right;
  font-size: 13px;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700; }

/****Current best deals****/
#currentBestDeals {
  height: 510px;
  padding-top: 60px; }

#currentBestDeals h2 {
  color: #212121;
  font-size: 36px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600; }

/**** .CurrentBestDeals *****/
.wrap-section {
  padding-left: 15px;
  padding-right: 15px; }

.currentBestDeals h2 {
  font-size: 2em;
  padding: 0.5em 0;
  border-bottom: 1px solid #D8D8D8; }
.currentBestDeals .contents {
  overflow: hidden;
  padding: 4em 0 5em; }
.currentBestDeals .bxslider {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 1.5em 0; }
  .currentBestDeals .bxslider > li .img {
    width: 40%;
    float: left; }
    .currentBestDeals .bxslider > li .img img {
      max-width: 100%; }
  .currentBestDeals .bxslider > li .info {
    width: 55%;
    margin-left: 5%;
    float: left; }
    .currentBestDeals .bxslider > li .info .label {
      font-size: 1.05em;
      font-weight: bold; }
    .currentBestDeals .bxslider > li .info li {
      list-style-type: square;
      padding-left: 0.5em;
      margin-left: 1.15em;
      margin-bottom: 0.5em; }
    .currentBestDeals .bxslider > li .info h1, .currentBestDeals .bxslider > li .info h2, .currentBestDeals .bxslider > li .info h3, .currentBestDeals .bxslider > li .info h4, .currentBestDeals .bxslider > li .info h5, .currentBestDeals .bxslider > li .info h6 {
      margin: 0 0 0.5em;
      font-size: 1.3em; }
    .currentBestDeals .bxslider > li .info p {
      padding: 0; }
    .currentBestDeals .bxslider > li .info p {
      padding-bottom: 1em;
      font-size: 0.95em;
      line-height: normal; }
    .currentBestDeals .bxslider > li .info .action {
      float: left; }

/**********/
#StageBestDeals {
  margin-top: 38px;
  margin-bottom: 90px; }

#StageBestDeals .Slides {
  list-style: none; }

#StageBestDeals .slide {
  height: 323px;
  width: 100%; }
  #StageBestDeals .slide .img {
    float: left;
    width: 35%; }
    #StageBestDeals .slide .img img {
      max-width: 100%; }
  #StageBestDeals .slide .info {
    float: right;
    margin-left: 5%;
    width: 60%; }
    #StageBestDeals .slide .info .btn {
      float: none !important; }

.bannerBestOfMonth {
  position: absolute;
  left: 0px;
  top: 140px; }

.cbdBigImg {
  float: left;
  margin-right: 11px; }

.cbdSmallImg {
  float: left;
  width: 243px;
  margin-right: 25px; }

.cbdSmallImgTop {
  margin-bottom: 11px; }

#StageBestDeals p {
  color: #000;
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 37px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300; }

#bestDealsManufactureur {
  font-size: 24px !important; }

.btnMoreDetails {
  background-color: #0445a6 !important;
  background: url(/Design/Images/buttons/btnMoreDetails.jpg) no-repeat right;
  display: inline-block;
  padding-left: 22px;
  padding-right: 34px;
  line-height: 44px;
  color: #fff !important;
  font-size: 14px;
  text-decoration: none;
  float: right;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700; }

.btnMoreDetails:hover {
  background: url(/Design/Images/backgrounds/bgBtnMoreDetailsOver.jpg) no-repeat; }

.StageBestDealsBtn {
  border-top: 1px solid #e5e5e5;
  padding-top: 13px;
  list-style: none;
  position: relative;
  top: 360px; }

.StageBestDealsBtn .StepLinks {
  float: left;
  width: 13px;
  height: 13px;
  margin-left: 7px;
  background: #8a8a8a; }

.StageBestDealsBtn .StepLinks:hover {
  width: 13px;
  height: 13px;
  background: #0445a6; }

.StageBestDealsBtn .over {
  width: 13px;
  height: 13px;
  background: #0445a6; }

/*********** Years in business ***************/
#BusinessQuote {
  background: url("/Design/Images/Backgrounds/blue_gradient.png");
  text-transform: uppercase;
  /*box-shadow: 0 15px 35px #CCC;
  box-shadow: 0 0px 15px #000 !important;
  filter: dropshadow(color=#000, offx=0, offy=15);
  filter:progid:DXImageTransform.Microsoft.DropShadow(scolor=#000, offx=0, offy=15);
  behavior: url(/Scripts/PIE-1.0.0/PIE.htc);*/ }

#BusinessQuote .years {
  font-size: 1.5em; }

#BusinessQuote .title {
  color: #FFF;
  text-shadow: none;
  font-size: 3.8em;
  font-size: 5vw;
  line-height: 1.2em;
  letter-spacing: -0.065em; }

#BusinessQuote .large {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700; }

#BusinessQuote .small {
  font-family: 'Open Sans', sans-serif;
  font-weight: lighter;
  font-size: 1.0em; }

#BusinessQuote {
  background: url("/Design/Images/Backgrounds/Dit-Equip-company-Fade.png") center center no-repeat #04409a;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /*min-width: 960px;*/
  /*width: inherit;*/
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 6% 0; }

/****Page Title****/
#PageTitle {
  background: url("/Design/Images/backgrounds/bg-concrete-top.jpg") center top #f3f3f3;
  height: 123px; }

#PageTitle h1, #PageTitle .contents-product h2 {
  color: #232e3e;
  font-size: 2.5em;
  margin: 1.25em 0 0.6em;
  padding: 0;
  float: left; }

.product .titleProduct h1{
  font-size: 1.5em;
  color: #3c3c3c;
  padding: 1em 0;
  float: left;
}


#PageTitle .callOnSkype {
  position: absolute;
  float: right;
  right: -6px; }

#PageTitle .pageImportantInfo {
  width: 317px;
  height: 155px;
  position: absolute;
  z-index: 1;
  float: right;
  right: -6px;
  background: url("/Design/Images/backgrounds/bgPageImportantInfo.png") center bottom;
  background-size: 100% 100%;
  margin-left: 10px;
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  font-weight: 600; }

#PageTitle .pageImportantInfo p {
  margin-left: 30px;
  margin-top: 18px;
  padding-bottom: 0; }

.pageImportantInfo .pagePhoneInterested {
  color: #4b4b4b;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.75em; }

.pageImportantInfo .pagePhoneOr {
  color: #808080;
  font-size: 1.2em;
  font-family: 'Open Sans', sans-serif; }

.pageImportantInfo .pagePhoneInformation {
  color: #043680;
  font-size: 1.35em;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.2em; }

#PageTitle .pageImportantInfo .pageCallFree {
  margin-top: 16px;
  width: 60%;
  padding-right: 12px;
  border-right: 1px solid #cdcdcd;
  float: left;
  text-transform: uppercase;
  font-size: 1.1em;
  line-height: 1.2em; }

#PageTitle .pageImportantInfo img {
  float: left;
  margin-left: 18px;
  margin-top: 20px; }

.content-page {
  padding: 3em 0;
  min-height: 500px; }

/****Page infos****/
#PageInfos {
  width: 100%; }

#PageInfosContainer {
  overflow: auto; }

/****New Products****/
#MenuNewEquipment {
  float: left;
  width: 200px;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  padding: 68px 15px 110px 14px; }

#MenuNewEquipment ul {
  list-style: none; }

#MenuNewEquipment ul ul {
  padding-left: 20px;
  font-size: 14px; }

#MenuNewEquipment ul ul ul {
  padding-left: 20px;
  font-size: 13px; }

#MenuNewEquipment a {
  display: block;
  font-family: 'Open Sans', sans-serif;
  color: #0445a6;
  text-decoration: none;
  margin-bottom: 15px; }

#MenuNewEquipment a:hover {
  color: #6ea8ff; }

#MenuNewEquipment a.activeMenu {
  font-weight: 700; }

.wrap-page-content {
  float: left;
  width: 710px;
  margin-top: 68px;
  margin-left: 13px;
  margin-bottom: 33px;
  position: relative; }

#infosNewEquipment {
  float: left;
  width: 710px;
  margin-top: 68px;
  margin-left: 13px;
  margin-bottom: 33px;
  position: relative; }

#infosNewEquipment h2 {
  padding-left: 23px;
  padding-bottom: 21px;
  border-bottom: 1px solid #e5e5e5;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 36px;
  margin-bottom: 20px;
  text-transform: uppercase; }

#infosNewEquipment h3 {
  padding-left: 23px;
  padding-bottom: 21px;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 25px; }

#infosNewEquipment h4 {
  padding-left: 23px;
  padding-bottom: 21px;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 20px; }

#infosNewEquipment p {
  padding-left: 23px;
  margin-bottom: 0;
  font-family: 'Open Sans', sans-serif;
  color: #3c3c3c;
  font-size: 17px; }

#infosNewEquipment ul {
  font-family: 'Open Sans', sans-serif;
  color: #3c3c3c;
  padding-left: 60px;
  padding-bottom: 20px;
  font-size: 17px; }

#infosNewEquipment ul ul {
  padding-bottom: 0px; }

#infosNewEquipment a {
  color: #0445a6;
  text-decoration: none; }

#infosNewEquipment a:hover {
  color: #6ea8ff; }

#infosNewEquipment .MenuTumbler {
  border: 0px none;
  width: 680px;
  margin-left: 23px; }

#infosNewEquipment .MenuTumbler td {
  text-align: center;
  width: 33%;
  background: #0445A6;
  padding: 3px; }

#infosNewEquipment .MenuTumbler a {
  display: block;
  font-family: 'Open Sans', sans-serif;
  color: #ffffff;
  text-decoration: none; }

#infosNewEquipment .MenuTumbler a:hover {
  color: #ffffff; }

#infosNewEquipment .MenuTumbler a.activeMenu {
  font-weight: 700; }

#infosNewEquipment .MenuTumbler td:hover {
  background: #115dcd; }

#infosNewEquipment .TableTumbler {
  border: 0px none;
  width: 680px;
  margin-left: 23px;
  margin-bottom: 50px; }

#infosNewEquipment .TableTumbler tr.grey {
  background: #e7e7e7; }

#infosNewEquipment .TableTumbler td {
  vertical-align: top;
  text-align: left;
  width: 33%;
  padding: 3px;
  font-family: 'Open Sans', sans-serif;
  color: #3c3c3c;
  font-size: 16px; }

#infosNewEquipment .TableTumbler td strong {
  font-size: 14px; }

#infosNewEquipment .TableTumbler2 {
  border: 0px none;
  width: 680px;
  margin-left: 23px;
  margin-bottom: 50px; }

#infosNewEquipment .TableTumbler2 tr.grey {
  background: #e7e7e7; }

#infosNewEquipment .TableTumbler2 td {
  vertical-align: top;
  text-align: left;
  width: 50%;
  padding: 3px;
  font-family: 'Open Sans', sans-serif;
  color: #3c3c3c;
  font-size: 16px; }

#infosNewEquipment .TableTumbler2 td.main {
  vertical-align: top;
  text-align: left;
  width: 50%;
  padding: 3px;
  font-family: 'Open Sans', sans-serif;
  color: #3c3c3c;
  font-size: 16px; }

#infosNewEquipment .TableTumbler2 td strong {
  font-size: 14px; }

#infosNewEquipment .TableTumbler2bis {
  border: 0px none;
  width: 700px;
  margin-bottom: 30px; }

#infosNewEquipment .TableTumbler2bis td {
  vertical-align: top;
  text-align: left;
  width: 60%; }

#infosNewEquipment .TableTumbler2bis td.main {
  width: 40%; }

#infosNewEquipment .TableTumbler4 {
  border: 0px none;
  width: 680px;
  margin-left: 23px;
  margin-bottom: 50px; }

#infosNewEquipment .TableTumbler4 tr.grey {
  background: #e7e7e7; }

#infosNewEquipment .TableTumbler4 td {
  vertical-align: top;
  text-align: left;
  width: 20%;
  padding: 3px;
  font-family: 'Open Sans', sans-serif;
  color: #3c3c3c;
  font-size: 16px; }

#infosNewEquipment .TableTumbler4 td.main {
  vertical-align: top;
  text-align: left;
  width: 40%;
  padding: 3px;
  font-family: 'Open Sans', sans-serif;
  color: #3c3c3c;
  font-size: 16px; }

#infosNewEquipment .TableTumbler4 td strong {
  font-size: 14px; }

#infosNewEquipment .TableTumbler8 {
  border: 0px none;
  width: 680px;
  margin-left: 23px;
  margin-bottom: 20px; }

#infosNewEquipment .TableTumbler8 tr.grey {
  background: #e7e7e7; }

#infosNewEquipment .TableTumbler8 td {
  vertical-align: center;
  text-align: center;
  width: 10%;
  padding: 3px;
  font-family: 'Open Sans', sans-serif;
  color: #3c3c3c;
  font-size: 14px; }

#infosNewEquipment .TableTumbler8 td.main {
  vertical-align: top;
  text-align: left;
  width: 30%;
  padding: 3px;
  font-family: 'Open Sans', sans-serif;
  color: #3c3c3c;
  font-size: 12px; }

#infosNewEquipment .TableTumbler8 td strong {
  font-size: 12px; }

#infosNewEquipment .TableTumbler8 tr.top td {
  vertical-align: bottom; }

#infosNewEquipment .TableTumbler8 td.note {
  vertical-align: bottom;
  text-align: left;
  padding: 3px;
  font-family: 'Open Sans', sans-serif;
  color: #3c3c3c;
  font-size: 12px; }

#infosNewEquipment .TextSpacer {
  padding: 5px 0px; }

#infosNewEquipment h5 {
  font-family: 'Open Sans', sans-serif;
  color: #0445a6;
  font-size: 24px;
  line-height: 24px; }

#infosNewEquipment h5 span {
  color: #000;
  font-size: 16px; }

#infosNewEquipment .Float {
  float: right;
  margin: 0px 0px 20px 20px;
  border: 0px none; }

.TableGenerale {
  width: 100%;
  border: 0px none; }

.TableGenerale td {
  padding: 10px;
  vertical-align: middle; }

.TableGenerale td.left {
  text-align: right; }

.TableGenerale td.right {
  text-align: left; }

.TableGenerale td.center {
  text-align: center;
  font-weight: bold; }

.PDFDownload {
  margin-bottom: 8px; }

.btnDownload {
  background-color: #0445a6 !important;
  background: url("/Design/Images/buttons/btnDownload.jpg") no-repeat right;
  display: inline-block;
  padding: 0px 34px 0px 22px;
  line-height: 44px;
  color: #fff !important;
  font-size: 14px;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700; }

.btnDownload:hover {
  background: url("/Design/Images/buttons/BtnDownloadOver.jpg") no-repeat right; }

/****Company Profile****/
.productsFoundResults.second {
  margin-top: 2.5em; }
  .productsFoundResults.second h1 {
    padding-bottom: 0.5em;
    border-bottom: 1px solid #e5e5e5;
    font-family: 'Open Sans', sans-serif;
    color: #000;
    font-size: 2.25em;
    margin-top: 0.5em;
    margin-bottom: 1em;
    line-height: 1.1em; }
  .productsFoundResults.second h3 {
    padding-bottom: 0.5em;
    font-family: 'Open Sans', sans-serif;
    color: #000;
    font-size: 1.8em;
    line-height: 1.1em;
    margin-top: 0.7em; }
  .productsFoundResults.second p {
    margin-bottom: 0;
    font-family: 'Open Sans', sans-serif;
    color: #3c3c3c;
    font-size: 17px; }
  .productsFoundResults.second ul {
    font-family: 'Open Sans', sans-serif;
    color: #3c3c3c;
    padding-left: 60px;
    padding-bottom: 20px;
    font-size: 17px; }
    .productsFoundResults.second ul a {
      color: #0445a6;
      text-decoration: none; }
      .productsFoundResults.second ul a:hover, .productsFoundResults.second ul a:focus {
        color: #6ea8ff; }

#infosCompanyProfile {
  float: left;
  width: 710px;
  margin-top: 68px;
  margin-left: 13px;
  margin-bottom: 33px; }

#infosCompanyProfile h2 {
  padding-left: 23px;
  padding-bottom: 21px;
  border-bottom: 1px solid #e5e5e5;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 36px;
  margin-bottom: 20px; }

#infosCompanyProfile h3 {
  padding-left: 23px;
  padding-bottom: 21px;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 25px; }

#infosCompanyProfile p {
  padding-left: 23px;
  margin-bottom: 0;
  font-family: 'Open Sans', sans-serif;
  color: #3c3c3c;
  font-size: 17px; }

#infosCompanyProfile ul {
  font-family: 'Open Sans', sans-serif;
  color: #3c3c3c;
  padding-left: 60px;
  padding-bottom: 20px;
  font-size: 17px; }

.companyProfileExperience {
  padding-left: 45px !important; }

.companyProfileExperience li {
  margin-bottom: 10px; }

/****Buying****/
#MenuBuying {
  float: left;
  width: 212px;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  padding: 68px 14px 110px 14px;
  /*height:500px;*/ }

#MenuBuying ul {
  list-style: none; }

#MenuBuying a {
  display: block;
  font-family: 'Open Sans', sans-serif;
  color: #0445a6;
  text-decoration: none;
  margin-bottom: 15px; }

#MenuBuying a:hover {
  color: #6ea8ff; }

#MenuBuying a.activeMenu {
  font-weight: 700; }

#infosBuying {
  float: left;
  width: 710px;
  margin-top: 68px;
  margin-left: 13px;
  margin-bottom: 33px; }

#infosBuying h2 {
  padding-left: 23px;
  padding-bottom: 21px;
  border-bottom: 1px solid #e5e5e5;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 36px;
  margin-bottom: 20px; }

#infosBuying h3 {
  padding-left: 23px;
  padding-bottom: 8px;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 25px; }

#infosBuying p {
  padding-left: 23px;
  margin-bottom: 0;
  font-family: 'Open Sans', sans-serif;
  color: #3c3c3c;
  font-size: 17px; }

#infosBuying ul {
  font-family: 'Open Sans', sans-serif;
  color: #3c3c3c;
  padding-left: 60px;
  padding-bottom: 20px;
  font-size: 17px; }

/****Selling****/
#MenuSelling {
  float: left;
  width: 212px;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  padding: 68px 14px 110px 14px;
  /*height:500px;*/ }

#MenuSelling ul {
  list-style: none; }

#MenuSelling a {
  display: block;
  font-family: 'Open Sans', sans-serif;
  color: #0445a6;
  text-decoration: none;
  margin-bottom: 15px; }

#MenuSelling a:hover {
  color: #6ea8ff; }

#MenuSelling a.activeMenu {
  font-weight: 700; }

#infosSelling {
  float: left;
  width: 710px;
  margin-top: 68px;
  margin-left: 13px;
  margin-bottom: 33px; }

#infosSelling h2 {
  padding-left: 23px;
  padding-bottom: 21px;
  border-bottom: 1px solid #e5e5e5;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 36px;
  margin-bottom: 20px; }

#infosSelling h3 {
  padding-left: 23px;
  padding-bottom: 8px;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 25px; }

#infosSelling p {
  padding-left: 23px;
  margin-bottom: 0;
  font-family: 'Open Sans', sans-serif;
  color: #3c3c3c;
  font-size: 17px; }

#infosSelling ul {
  font-family: 'Open Sans', sans-serif;
  color: #3c3c3c;
  padding-left: 60px;
  padding-bottom: 20px;
  font-size: 17px; }

#sellingProcedure {
  padding-left: 40px !important; }

#sellingProcedure li {
  margin-bottom: 10px; }

/****Search Products****/
.searchProducts, .side-menu {
  width: 236px;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  float: left;
  padding: 4em 0.9em 5em; }

.side-menu ul {
  list-style: none; }
  .side-menu ul li.spliter {
    display: block;
    border-bottom: 1px solid #e5e5e5;
    padding: 5px 0px 5px 0px;
    margin-bottom: 22px; }
  .side-menu ul ul {
    padding-left: 1em;
    font-size: 0.95em; }
    .side-menu ul ul ul {
      font-size: 0.85em; }
.side-menu a {
  display: block;
  font-family: 'Open Sans', sans-serif;
  color: #0445a6;
  text-decoration: none;
  margin-bottom: 15px; }
  .side-menu a:hover, .side-menu a:focus {
    color: #6ea8ff; }
  .side-menu a.activeMenu {
    font-weight: 700; }

#searchImportant {
  font-size: 1.45em; }

.searchProducts p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #383838;
  font-size: 1.1em;
  margin-bottom: 1em;
  margin-top: 2.1em;
  padding: 0;
  width: 100%;
  display: block; }

.label-search {
  margin-bottom: 1.5em; }
  .label-search.right {
    float: right; }
  .label-search.large {
    width: 50%; }
  .label-search.title {
    margin-bottom: 0; }

.searchProducts .customSelect {
  width: 197px;
  padding-left: 10px;
  padding-right: 3px;
  color: #0445a6;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  padding-top: 9px;
  padding-bottom: 9px;
  border: 1px solid #e6e6e6;
  outline: none;
  background: url(/Design/Images/buttons/btnDropDown.png) no-repeat right; }

.customSelect.customSelectHover {
  /* Styles for when the select box is hovered */ }

.customSelect.customSelectOpen {
  /* Styles for when the select box is open */ }

.customSelect.customSelectFocus {
  /* Styles for when the select box is in focus */ }

.customSelectInner {
  /* You can style the inner box too */ }

.searchProducts .refineSearch {
  margin-top: 2em; }

.searchProducts .refineSearch p {
  font-weight: 400;
  font-size: 1em; }

.searchProducts p.refineSearchTitle, .searchProducts a.refineSearchTitle {
  font-weight: 700;
  font-size: 1.15em;
  margin: 0;
  float: none;
  display: block;
  text-decoration: none;
  color: #383838; }
.searchProducts a.refineSearchTitle {
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  display: block;
  text-align: left;
  width: 100%;
  border: 0 none;
  font-size: 1.15em;
  line-height: 1em;
  padding-right: 20px;
  outline: none; }
.searchProducts p.title-search {
  margin: 0;
  font-size: 1.34em; }

#Refine-mobile {
  background: url("/Design/Images/Icon/plus-icon.png") no-repeat right center; }
  #Refine-mobile:hover {
    outline: none;
    color: #0445a6;
    background: url("/Design/Images/Icon/plus-icon-over.png") no-repeat right center; }
  #Refine-mobile.active {
    background: url("/Design/Images/Icon/minus-icon.png") no-repeat right center; }
    #Refine-mobile.active:hover {
      background: url("/Design/Images/Icon/minus-icon-over.png") no-repeat right center; }

.toogle-search {
  display: none;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s; }
  .toogle-search.active {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    display: block; }

.searchProducts .refineSearch input {
  margin-right: 10px;
  margin-top: 28px;
  cursor: pointer; }

.searchProducts .refineSearch label {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #363636; }

.btnSearchProducts {
  text-align: center; }

a.btnSearch {
  display: block;
  background: #0445a6;
  border: 2px solid #0445A6;
  width: 100%;
  color: #fff !important;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  line-height: 43px;
  padding: 0;
  margin-top: 48px;
  text-align: center;
  margin-right: 11px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

.btnSearch:hover {
  border: 2px solid #033074;
  background: #033074; }

a.btnReset {
  display: block;
  background: none;
  border: 2px solid #0445A6;
  color: #0445A6;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  line-height: 43px;
  padding: 0 20px 0 20px;
  margin-top: 8px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

a.btnReset:hover {
  border-color: #000;
  color: #000;
  background: #EEE; }

/****Products found****/
.productsFoundResults {
  float: right;
  margin-left: 2em;
  width: 76.5%;
  padding-top: 0.5em; }
  .productsFoundResults img {
    max-width: 100%;
    margin: 0.75em 0; }
  .productsFoundResults table {
    margin: 1em 0;
    width: 100%;
    overflow: scroll; }
    .productsFoundResults table a {
      display: inline-block;
      margin-right: 0.5em;
      color: #0445a6;
      text-decoration: none; }
      .productsFoundResults table a:hover, .productsFoundResults table a:focus {
        color: #6ea8ff; }
    .productsFoundResults table tr td {
      vertical-align: top;
      padding-bottom: 0.75em; }
      .productsFoundResults table tr td.main {
        min-width: 160px;
        padding-right: 1em; }

.titleProductsFound {
  padding-bottom: 21px;
  border-bottom: 1px solid #E5E5E5; }

.titleProductsFound h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.25em;
  font-weight: normal;
  color: #3c3c3c;
  padding-bottom: 0px;
  line-height: 1em;
  margin-bottom: 0.5em; }

.titleProductsFound .resultsFound {
  font-family: 'Open Sans', sans-serif;
  font-size: 19px;
  font-weight: 700;
  color: #0445a6;
  padding-bottom: 0px; }

.productFound {
  padding-bottom: 2em;
  margin-top: 1.4em;
  border-bottom: 1px solid #e5e5e5; }
  .productFound a {
    display: block;
    padding: 1.5em 0; }
    .productFound a:hover img, .productFound a:focus img {
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
      filter: alpha(opacity=65);
      -moz-opacity: 0.65;
      -khtml-opacity: 0.65;
      opacity: 0.65; }
  .productFound .img {
    position: relative;
    float: left;
    width: 30%; }
    .productFound .img img {
      margin: 0;
      float: none;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100);
      -moz-opacity: 1;
      -khtml-opacity: 1;
      opacity: 1;
      width: 100%;
      -webkit-transition: opacity 0.3s;
      -moz-transition: opacity 0.3s;
      -ms-transition: opacity 0.3s;
      -o-transition: opacity 0.3s;
      transition: opacity 0.3s; }
    .productFound .img .new-listing-label {
      position: absolute;
      top: -6px;
      right: -6px;
      background: url("/Design/Images/Layout/label-new-listing-small@2x.png") no-repeat;
      background-size: 100% 100%;
      width: 105.5px;
      height: 73.5px; }
      .productFound .img .new-listing-label.medium {
        top: -6px;
        right: -6px;
        width: 106px;
        height: 74px;
        background: url("/Design/Images/Layout/label-new-listing-small@2x.png") no-repeat;
        background-size: 100% 100%; }
  .productFound .infoProductFound {
    float: left;
    width: 68%;
    margin-left: 2%; }
    .productFound .infoProductFound table tr td {
      vertical-align: top;
      font-size: 1em;
      color: #3c3c3c;
      padding-bottom: 0.5em;
      font-family: 'Open Sans', sans-serif; }
      .productFound .infoProductFound table tr td.title {
        font-weight: 700;
        width: 30%;
        font-size: .95em;
        padding-right: 0.75em; }
      .productFound .infoProductFound table tr td.data {
        color: #68696b;
        font-size: 0.9em; }
  .productFound:last-child {
    border-bottom: 0 none; }

.newListing {
  position: absolute;
  right: 23px;
  margin-top: 11px; }

.newListing p {
  color: #0445a6 !important;
  font-size: 13px !important;
  padding: 0 !important;
  float: left;
  margin-right: 6px; }

.newListing img {
  float: right; }

.productFound .imgProductFound {
  float: left;
  max-width: 100%; }

.productFound .titleDesProductFound {
  font-weight: 700;
  float: left; }

.productFound .descriptionProductFound {
  width: 254px;
  float: right; }

.productFound .btnProductFound {
  float: right; }

a.requestQuotation, a.productMoreInfo, a.DeleteProduct {
  float: left;
  display: block;
  font-size: 0.9em;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  text-decoration: none;
  background: #EB9D00;
  line-height: 34px;
  padding: 0 1.25em;
  margin-left: 15px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
  a.requestQuotation:hover, a.requestQuotation:focus, a.productMoreInfo:hover, a.productMoreInfo:focus, a.DeleteProduct:hover, a.DeleteProduct:focus {
    background: #0445a6; }

.lastProductMargin {
  height: 33px; }

/***Back to search****/
.backToSearch {
  float: right; }

.backToSearch a {
  float: left;
  margin-top: 53px; }

.backToSearch img {
  float: left !important;
  position: inherit !important; }

a.backToSearchLink {
  position: relative;
  top: 0;
  display: block;
  height: 36px;
  line-height: 36px;
  padding: 0 13px 0 30px;
  color: #043c8f;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;
  margin-top: 60px;
  background: url("/Design/Images/layout/back-btn.png") top left;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
  a.backToSearchLink:hover, a.backToSearchLink:focus {
    color: #FFF;
    background: url("/Design/Images/layout/back-btn-blue.png") top left; }

/****Product****/
.titleProduct h2 {
  font-size: 1.5em;
  color: #3c3c3c;
  padding: 1em 0;
  float: left; }

.titleProduct .navigationProducts {
  float: right;
  border-left: 1px solid #e5e5e5;
  padding: 32px 11px 0 22px; }

.titleProduct .btnPrevProduct {
  float: left;
  margin-right: 16px; }

.titleProduct .btnNextProduct {
  float: left; }

.titleProduct .navigationProducts p {
  clear: both;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: #0445a6;
  padding-top: 10px;
  padding-bottom: 14px !important; }

.product .detailsProduct {
  position: relative;
  border-top: 1px solid #e5e5e5;
  background: #f7f7f7;
  padding: 1.5em 0 0.5em;
  margin: 1.75em 0 0;
  margin: 0;
  /*.new-listing-label{
    position: absolute;
    top: -8px;
    right: -8px;
  }*/ }

.new-listing-label {
  position: absolute;
  background-size: 100% 100%; }
  .new-listing-label.medium {
    top: -6px;
    right: -8px;
    width: 106px;
    height: 74px;
    background: url("/Design/Images/Layout/label-new-listing-medium@2x.png") no-repeat;
    background-size: 100% 100%; }

.product .detailsProductLeft {
  width: 50%;
  float: left; }

.product .detailsProductRight {
  width: 50%;
  float: left; }

.product .detailsProductTitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.95em;
  line-height: 1em;
  font-weight: 700;
  color: #000;
  padding: 0 0.5em 1em 1.5em;
  width: 50%;
  vertical-align: top; }

.product .detailsProductDes {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.95em;
  font-weight: normal;
  color: #000;
  position: relative;
  padding: 0 0.5em 1em 0;
  width: 50%;
  vertical-align: top; }

#PageInfos .product-fiche {
  margin: 0.5em 0 0; }
  #PageInfos .product-fiche .img {
    margin-top: 2.15em;
    float: left;
    width: 50%; }
    #PageInfos .product-fiche .img .inner {
      overflow: hidden;
      padding: 0 0 3em 0; }
      #PageInfos .product-fiche .img .inner li a {
        display: table;
        width: 100%; }
        #PageInfos .product-fiche .img .inner li a img {
          width: 100%;
          display: table-cell;
          vertical-align: middle; }
    #PageInfos .product-fiche .img .swipe-wrap img {
      width: 100% !important; }
    #PageInfos .product-fiche .img .pager-thumb {
      padding: 1em 0;
      margin-right: -2%; }
      #PageInfos .product-fiche .img .pager-thumb a {
        float: left;
        display: block;
        width: 18%;
        margin-right: 2%;
        margin-bottom: 2%;
        overflow: hidden;
        height: 50px;
        margin-bottom: 10px;
        background: #CCC; }
        #PageInfos .product-fiche .img .pager-thumb a img {
          width: 100%; }
        #PageInfos .product-fiche .img .pager-thumb a.active {
          opacity: 0.5; }
    #PageInfos .product-fiche .img .bx-wrapper, #PageInfos .product-fiche .img .bx-wrapper .bx-viewport {
      background: #EEE; }
  #PageInfos .product-fiche .imagesProduct {
    margin-top: 36px;
    max-width: 100%; }

.imagesProduct {
  margin-top: 36px; }

.thumbsImagesProducts {
  margin-top: 12px; }

.thumbsImagesProducts img {
  float: left;
  margin-right: 11px; }

.productLastThumb {
  margin-right: 0 !important; }

.descriptionProduct {
  float: right;
  width: 45%;
  margin-top: 35px;
  margin-left: 5%;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: normal;
  color: #3c3c3c;
  border-bottom: 1px solid #e5e5e5; }

.descriptionProduct p {
  line-height: 18px;
  font-size: 13px !important; }

.descriptionProduct p.titleDescProduct {
  font-weight: 700 !important;
  font-size: 22px !important;
  padding-bottom: 20px;
  line-height: 13px !important; }

.btnProduct p.titleDescProduct {
  font-weight: 700 !important;
  font-size: 22px !important;
  padding-bottom: 20px;
  line-height: 13px !important;
  font-family: 'Open Sans', sans-serif; }

.btnProduct {
  float: right;
  margin-top: 21px;
  width: 100%; }

.btnProduct a {
  float: right; }

.btnProduct .btnPpt {
  display: block;
  height: 42px;
  line-height: 42px;
  border: 1px solid #e5e5e5;
  padding: 0 12px 0 12px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #878787;
  text-decoration: none;
  float: left;
  margin: 0 14px 14px 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
  .btnProduct .btnPpt:hover, .btnProduct .btnPpt:focus {
    border: 1px solid #BBB;
    background: #EEE;
    /*-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;*/ }

.btnProduct .btnMp3 {
  display: block;
  width: 30px;
  height: 42px;
  line-height: 42px;
  border: 1px solid #e5e5e5;
  padding: 0 8px 0 8px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #878787;
  text-decoration: none;
  float: right;
  margin-right: 12px; }

.product .descriptionProduct ul li {
  list-style-type: square;
  padding-left: 0.5em;
  margin-left: 1.25em;
  margin-bottom: 0.5em; }

.product .requestQuotation {
  display: block;
  font-size: 20px;
  font-weight: 700 !important;
  font-family: 'Open Sans', sans-serif;
  color: #fff !important;
  text-decoration: none;
  text-align: center;
  background: #EB9D00;
  width: 100% !important;
  height: 48px !important;
  line-height: 48px !important;
  padding: 0 !important;
  margin: 28px 0 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
  .product .requestQuotation:hover, .product .requestQuotation:focus {
    background: #E20303; }

.shareProduct {
  float: left;
  margin-top: 61px;
  border-top: 1px solid #e5e5e5;
  padding-top: 19px;
  width: 79.5%; }

.shareProduct .productPhone {
  float: left;
  margin-left: 14px;
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 24.77px;
  border: 0;
  padding: 0;
  margin-bottom: 1em !important; }

.shareProduct .productPhone p {
  padding: 0 0 0.5em;
  font-weight: bold !important;
  margin: 0 auto;
  display: inline-block;
  border: 0; }

.shareProduct .productPhoneInterested {
  color: #4b4b4b;
  font-family: 'Open Sans', sans-serif; }

.shareProduct .productPhoneOr {
  color: #808080;
  font-size: 20.96px !important;
  font-family: 'Open Sans', sans-serif; }

.shareProduct .productPhoneInformation {
  color: #043680;
  font-size: 20.96px !important;
  font-family: 'Open Sans', sans-serif; }

.shareProduct .productPhoneNumber {
  color: #0257d9;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9em !important;
  letter-spacing: 4px;
  font-weight: normal !important;
  font-style: normal !important;
  margin-bottom: 12px; }

.shareProduct .shareIcons {
  display: none !important;
  float: right;
  margin-left: 59px;
  margin-top: 7px;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 11px !important;
  color: #36424a;
  font-weight: normal !important; }
  .shareProduct .shareIcons a {
    position: relative;
    top: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s; }
    .shareProduct .shareIcons a:hover, .shareProduct .shareIcons a:focus {
      top: -4px;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
      filter: alpha(opacity=50);
      -moz-opacity: 0.5;
      -khtml-opacity: 0.5;
      opacity: 0.5; }

.shareProduct a {
  float: left;
  height: 32px;
  display: block;
  text-decoration: none;
  padding: 0 24px;
  border-right: 1px solid #e3e3e3; }
  .shareProduct a p {
    float: left;
    margin-left: 8px;
    line-height: 36px;
    height: 32px;
    padding-bottom: 0; }
  .shareProduct a img {
    float: left;
    margin-top: 4px; }
  .shareProduct a.twitter img {
    margin-top: 7px; }
  .shareProduct a.email {
    border-right: 0; }
    .shareProduct a.email img {
      margin-top: 9px; }

.print {
  margin-top: 61px;
  float: left;
  width: 20%;
  height: 81px;
  border-left: 1px solid #e3e3e3;
  border-top: 1px solid #e3e3e3; }

.print a {
  background: #e5e5e5;
  display: block;
  height: 37px;
  width: 80%;
  margin-left: 10%;
  margin-top: 23px;
  text-decoration: none;
  text-align: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

.print a:hover, .print a:focus {
  background: #CCC; }

.print img {
  margin-top: 2px;
  margin-left: -15px;
  display: inline-block; }

.print p {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px !important;
  color: #0445a6 !important;
  font-weight: 700 !important;
  margin-top: 6px;
  margin-left: 12px;
  display: inline-block; }

/****Contact****/
#formContact {
  margin-top: 68px;
  margin-bottom: 50px; }
  #formContact h2 {
    font-family: 'Open Sans', sans-serif;
    color: #000;
    font-size: 30px; }
  #formContact .inner {
    min-height: 120px; }

.form-part {
  margin: 1.5em 0; }
  .form-part .FormTable td {
    vertical-align: top;
    padding: 0; }

#map_canvas {
  width: 90%;
  height: 585px;
  margin-top: 0px;
  border: solid 1px #999; }

/****Form****/
.FormTable {
  font-family: 'Open Sans', sans-serif; }
  .FormTable .label-form {
    margin-bottom: 1em;
    font-family: 'Open Sans', sans-serif; }
    .FormTable .label-form input, .FormTable .label-form select, .FormTable .label-form textarea {
      box-shadow: none;
      border-radius: 0;
      color: #222;
      font-family: 'Open Sans', sans-serif;
      cursor: pointer; }
      .FormTable .label-form input:focus, .FormTable .label-form select:focus, .FormTable .label-form textarea:focus {
        border: 1px solid #004ab9; }
    .FormTable .label-form .chosen-container-multi .chosen-choices {
      background: #FFF !important;
      border: 1px solid #C4C4C4; }
    .FormTable .label-form .chosen-container-active .chosen-choices {
      box-shadow: none !important;
      background: #FFF !important;
      border: 1px solid #004ab9; }
    .FormTable .label-form.col-25 {
      padding-right: 1em; }

/*.FormTable .FormTextBox{
  width:100%;
  padding: 0.5em 0.75em;
  color:#0445a6;
  font-family: 'Open Sans', sans-serif;
  font-size:13px;
  font-weight:400;
  margin-bottom:15px;
  border:1px solid #C4C4C4;
  outline:none;
  //margin-left:20px;
}*/
.FormTextBox {
  width: 100%;
  color: #0445a6;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  padding: 0.55em;
  margin-bottom: 15px;
  border: 1px solid #CCC;
  outline: none;
  -webkit-appearance: none; }

.FormTable .FormTextArea {
  padding-left: 10px;
  padding-right: 3px;
  color: #0445a6;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  padding-top: 9px;
  padding-bottom: 9px;
  outline: none; }

.FormTable .FormBouton {
  background-color: #eaa313 !important;
  background: url(/Design/Images/buttons/btnBuying.jpg) no-repeat right;
  display: block;
  width: 140px !important;
  height: 61px !important;
  padding-left: 22px;
  padding-right: 58px;
  line-height: 61px;
  color: #fff !important;
  font-size: 22px;
  text-decoration: none;
  float: right;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  border: none;
  cursor: pointer; }

.FormTable .FormBouton:hover {
  background: url(/Design/Images/backgrounds/bgBtnSendOver.jpg) no-repeat; }

.btnSubmission .FormBouton {
  background-color: #eaa313 !important;
  background: url(/Design/Images/buttons/btnBuying.jpg) no-repeat right;
  display: block;
  width: 305px !important;
  height: 61px !important;
  padding-left: 22px;
  padding-right: 58px;
  line-height: 61px;
  color: #fff !important;
  font-size: 22px;
  text-decoration: none;
  margin: 0px auto;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  border: none;
  cursor: pointer; }

.btnSubmission .FormBouton:hover {
  background-color: #fcb702 !important;
  background: url(/Design/Images/backgrounds/bgBtnSendOver.jpg) top right no-repeat; }

.FormTable .FormTextBoxError {
  width: 100%;
  padding-left: 10px;
  padding-right: 3px;
  color: #0445a6;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 15px;
  border: 2px solid #B70000;
  outline: none; }

.FormTable .FormTextAreaError {
  padding-left: 10px;
  padding-right: 3px;
  color: #0445a6;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  padding-top: 9px;
  padding-bottom: 9px;
  border: 2px solid #B70000;
  outline: none; }

/***************************************************************************************/
/* Formulaire de soumission                                                            *
/***************************************************************************************/
.TabEstimation {
  width: 100%;
  margin-bottom: 15px;
  padding: 15px 0;
  border-bottom: 1px solid #CCC; }

.TabEstimation td {
  width: 25%;
  vertical-align: top;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-style: normal; }

.TabInfo {
  width: 100%;
  margin-bottom: 30px;
  text-align: center; }

.TabInfo .First {
  text-align: left; }

.TabInfo td {
  padding: 15px 0 10px;
  vertical-align: top;
  border-bottom: 1px dashed #CCC;
  padding-left: 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-style: normal; }

span.Title {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 25px;
  display: block;
  margin-bottom: 25px; }

.TabCoomentaire {
  width: 100%;
  padding-bottom: 15px; }

.TabCoomentaire td {
  width: 50%;
  vertical-align: top;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-style: normal; }

.TabCoomentaire textarea {
  min-width: 735px;
  max-width: 735px; }

.SubmitButton {
  width: 200px;
  padding: 12px 0;
  margin: 0 auto;
  border: none;
  background: #D10000;
  color: #FFF;
  cursor: pointer;
  font-size: 15px; }

.SubmitButton:hover {
  background: #F40000; }

.CenterAll {
  text-align: center;
  margin: 0 auto; }

.BoiteOui {
  display: none;
  border: 1px solid #F00; }

.BoiteOui td {
  vertical-align: top; }

.BoiteSolin {
  width: 100% !important;
  vertical-align: top;
  display: none;
  border: 1px solid #F00; }

.SpaceTd {
  padding-left: 50px; }

#Urgence {
  width: 840px;
  height: 130px;
  background: url(/Design/Pages/urgence900.png) 0px 0px no-repeat;
  padding: 15px 20px 15px 40px;
  color: white;
  margin: 0px auto;
  font-family: BebasNeueRegular;
  font-size: 1.4em;
  margin-top: 50px; }

.EstimationTextBox, .TabCoomentaire textarea, .TabInfo input, select {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #AEB7BB;
  margin: 4px 0;
  padding: 8px;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.05em;
  cursor: pointer; }

#ChoixTypeToit {
  width: 249px; }

.TabInfo input[type="text"] {
  text-align: center; }

.TabInfo input[type="radio"] {
  margin-top: 7px; }

.EstimationTextBoxError {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #F00;
  margin: 4px 0;
  padding: 4px;
  background-color: #FEE; }

#boiteFormulaire {
  margin: 2em 0; }

#TitreEstimation {
  color: #000;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 32px;
  text-shadow: 0 1px white; }

span.mandatory {
  font-size: 0.8em;
  display: block;
  margin: 20px 0px 20px 0px; }

/*.btnSubmission{ margin:0px 0px 20px 0px; }
.btnSubmission a, .btnSubmission input{ border:none; background:#0445a6; padding:14px 62px; color:#fff; font-size:1.188em; text-decoration:none; text-transform:uppercase; display:inline-block;
  border-radius: 50px 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px 50px;
  -webkit-border-radius: 50px 50px 50px 50px;
  cursor: pointer;
}
.btnSubmission a:hover, .btnSubmission a:active, .btnSubmission input:hover{ background:#115dcd; }  */
/***************************************************************************************/
/*  F O O T E R                                                                        */
/***************************************************************************************/
.menu-right {
  float: right;
  width: 80%; }

#dark {
  background: url("/Design/Images/backgrounds/bgListWithUs.jpg") center center;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center; }

#dark .Title {
  color: #fff;
  font-size: 2.5em;
  padding: 0 !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  line-height: 0.9em;
  margin: 0 0 0.5em; }

#dark .Text {
  color: #fff;
  font-size: 2em;
  /*line-height:71px !important;*/
  font-style: italic;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  padding-bottom: 20px;
  line-height: 1em; }

#dark .btnList {
  background-color: #0445a6 !important;
  background: url(/Design/Images/buttons/btnList.jpg) no-repeat right;
  display: inline-block;
  height: 70px !important;
  padding-left: 22px;
  padding-right: 48px;
  line-height: 70px;
  color: #fff !important;
  font-size: 1.25em;
  text-decoration: none;
  margin: 0px auto;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-align: left !important; }

#dark .btnList:hover {
  background-color: #125ece !important;
  background: url(/Design/Images/buttons/btnListOver.jpg) no-repeat right; }

/****Bottom footer****/
#bottomFooter {
  background: url(/Design/Images/backgrounds/bgFooter.png) repeat-x;
  height: 248px; }
  #bottomFooter .logo {
    float: left;
    margin-top: 50px;
    width: 20%; }
    #bottomFooter .logo a {
      display: block;
      width: 100%; }
      #bottomFooter .logo a img {
        max-width: 100%;
        width: 100%; }

#numTel {
  float: right;
  color: #1b66d2;
  font-size: 25.66px;
  margin-top: 65px;
  padding-bottom: 25px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600; }

#menuFooter {
  float: right;
  list-style: none; }

#menuFooter li {
  display: inline-block;
  margin-left: 15px; }

#menuFooter a {
  float: left;
  display: block;
  color: #9b9b9b;
  font-size: 14px;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  -ms-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s; }

#menuFooter a:hover {
  color: #1b66d2; }

#copyrights {
  float: left;
  color: #7b7b7b;
  font-size: 11px;
  margin-top: 67px; }

#copyrights p {
  margin-top: 0px;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  line-height: 1em; }

#concepteurs {
  float: right;
  color: #7a7a7a;
  font-size: 11px;
  margin-top: 52px; }

#concepteurs a {
  display: inline-block;
  width: 64px;
}

#concepteurs img {
  width: 100%;
}

#concepteurs p {
  float: left;
  margin-top: 7px;
  margin-right: 6px;
  font-family: 'Open Sans', sans-serif; }

/***************************************************************************************/
/*  Fancybox                                                                           */
/***************************************************************************************/
.MediaBox {
  margin-bottom: 20px;
  margin-right: 20px;
  position: relative;
  width: 225px;
  height: 142px; }

.n-box {
  margin-bottom: 18%; }

.MediaBox.overlay {
  position: relative;
  display: block;
  overflow: hidden; }

.MediaBox a {
  display: block; }

.MediaBox.overlay img {
  width: 225px;
  height: 142px;
  /*width:100%; border-radius:12px;*/ }

.MediaBox.overlay .over {
  position: absolute;
  bottom: -90px;
  left: 0;
  display: block;
  width: 225px;
  /*width: 80%;*/
  text-transform: uppercase;
  padding: 48px 0% 15px;
  /*border-radius: 12px;*/
  text-decoration: none;
  text-align: center;
  color: #FFF;
  font-family: 'proxima_nova_rgbold', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.2em;
  background: url("/Design/Images/Backgrounds/Shadow_Overlay_Media.png") repeat-x left top;
  text-shadow: 0 1px 5px black;
  -webkit-transition: bottom 0.4s;
  -moz-transition: bottom 0.4s;
  -o-transition: bottom 0.4s;
  -ms-transition: bottom 0.4s;
  transition: bottom 0.4s; }

.MediaBox.overlay:hover .over, .MediaBox.overlay:active .over {
  bottom: 0; }

.play, .virtual, .Dimen3D {
  width: 93px;
  height: 92px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -46px;
  margin-left: -46px; }

.overlay .icon {
  display: block;
  width: 63px;
  height: 63px;
  background: url("/Design/Images/Icon/Plus@2x.png") no-repeat center center;
  position: absolute;
  top: 70%;
  left: 50%;
  margin-left: -31.5px;
  margin-top: -31.5px;
  background-size: 100% 100%; }
.overlay .play {
  background: url("/Design/Images/Icon/play@2x.png") no-repeat 0 0;
  background-size: 100% 100%; }

.MediaBox.overlay:hover .icon, .MediaBox.overlay:focus .icon {
  background: url("/Design/Images/Icon/Plus-over@2x.png") no-repeat 0 0;
  background-size: 100% 100%; }
.MediaBox.fancybox-media:hover .play, .MediaBox.fancybox-media:focus .play {
  background: url("/Design/Images/Icon/play-over@2x.png") no-repeat 0 0;
  background-size: 100% 100%; }

.virtual {
  background: url("/Design/Images/Icon/virtual_Btn_icon_93x92.png") no-repeat 0 0; }

.Dimen3D {
  background: url("/Design/Images/Icon/3D_Btn_icon_93x92.png") no-repeat 0 0; }

.virtual:hover, .Dimen3D:hover {
  background-position: 0 -92px; }

a.OverPlus {
  position: relative;
  display: block;
  float: left; }

.overlay {
  display: block;
  position: relative;
  width: 225px;
  height: 142px;
  /*width: 100%; height: 100%;*/
  position: absolute;
  bottom: 0;
  left: 0; }

#concepteurs img {
  float: right; }

.remove-list {
  width: 100%;
  list-style: none;
  margin: 2.5em 0 1em; }
  .remove-list li {
    padding: 1em;
    margin-bottom: 5px;
    background: #EEE;
    -webkit-transition: background 0.3s;
    -moz-transition: background 0.3s;
    -ms-transition: background 0.3s;
    -o-transition: background 0.3s;
    transition: background 0.3s; }
    .remove-list li:hover {
      background: #DDD; }
    .remove-list li .data {
      padding: 0.5em  0.5em 0.5em 0; }
    .remove-list li .DeleteProduct {
      width: 100%;
      display: block;
      text-align: center;
      margin: 0;
      padding: 0;
      background: none;
      border: 2px solid #333;
      color: #333; }
      .remove-list li .DeleteProduct:hover, .remove-list li .DeleteProduct:focus {
        background: #333;
        color: #FFF; }

.fixed-side {
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 200; }
  .fixed-side a.btn {
    position: relative;
    left: -16px;
    display: inline-block;
    background: none;
    opacity: 0.925;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s; }
    .fixed-side a.btn:hover, .fixed-side a.btn:focus {
      background: #FFF;
      left: -10px;
      opacity: 1; }

.head-toggle {
  position: relative;
  font-weight: 700;
  margin: 1em 0;
  color: #232e3e; }
  .head-toggle .btn-toggle-menu-filters {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    border: 0;
    margin: 0;
    width: 25px;
    height: 25px;
    margin-top: -12.5px;
    background: none;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("/Design/Images/Icon/plus-icon-large.png"); }

#menu-filters-content.active .head-toggle .btn-toggle-menu-filters {
  background-image: url("/Design/Images/Icon/minus-icon-large.png"); }

#menu-filters {
  display: block; }

/***************************************************************************************/
/*  C L E A R F I X                                                                */
/***************************************************************************************/
.clearfix {
  clear: both; }

/************** BASIC GRID **********************/
.pull-left {
  float: left; }

.pull-right {
  float: right; }

.col-25 {
  width: 25%; }

.col-33 {
  width: 33.33333%; }

.col-50 {
  width: 50%;
  padding-right: 3%; }

.col-75 {
  width: 75%; }

.col-100 {
  width: 100%; }

/****** @MEDIAS *****/
/*** GRID VARIABLES ****/
.contents {
  width: 90%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  position: relative; }
  @media (max-width: 499px) {
    .contents {
      width: 100%; } }
  @media (min-width: 500px) and (max-width: 767px) {
    .contents {
      width: 97%; } }
  @media (min-width: 981px) and (max-width: 1099px) {
    .contents {
      width: 87%; } }
  @media (min-width: 1100px) {
    .contents {
      width: 960px; } }
  @media (min-width: 1260px) {
    .contents {
      width: 1200px; } }

/***** MEDIAS QUERIES *****/
@media (min-width: 1100px) {
  .fixed-side {
    display: block; }

  #man {
    right: -318px; }

  #mobile-nav {
    display: none !important; }

  #search-bar {
    display: none !important; }

  .productsFoundResults {
    width: 70%; }

  #Refine-mobile {
    display: none !important; }

  .toogle-search {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    display: block !important; } }

@media (max-width: 1260px) {
  .usp-header {
    right: 280px !important;
  }
}
@media (min-width: 981px) and (max-width: 1099px) {
  .fixed-side {
    display: block; }

  embed {
    display: none; }

  #Categories .box {
    width: 32%;
    margin-right: 2%; }
    #Categories .box.last {
      margin-right: 0; }
    #Categories .box .img {
      max-height: 280px;
      overflow: hidden; }

  #man {
    display: none; }

  #bottomFooter {
    text-align: center; }
    #bottomFooter .logo {
      float: none;
      margin-left: auto;
      margin-right: auto; }
    #bottomFooter .menu-right {
      float: none;
      width: 100%; }
    #bottomFooter #numTel {
      float: none; }
    #bottomFooter #menuFooter {
      float: none; }

  #Logo {
    display: none; }

  #mobile-logo {
    display: block; }

  #infosTitle {
    padding-top: 3.75em;
    font-size: 0.95em; }

  #Menu a {
    padding: 0 9px;
    font-size: 0.8em; }

  #mobile-nav {
    display: none !important; }

  #search-bar {
    display: none !important; }

  .shareProduct a {
    padding: 0 10px; }

  /*** Search filters **/
  .searchProducts {
    background-color: #F1F1F1; }

  .head-toggle .btn-toggle-menu-filters {
    display: block; }

  #menu-filters {
    display: none; }

  .searchProducts, .side-menu {
    width: 100%;
    padding: 2.5em 0.9em 1em; }
    .searchProducts .refineSearch, .side-menu .refineSearch {
      margin: 0 -2%;
      padding: 0 2%; }
    .searchProducts .label-search, .side-menu .label-search {
      width: 30%;
      margin-left: 1.5%;
      margin-right: 1.5%;
      float: left; }
      .searchProducts .label-search select, .side-menu .label-search select {
        width: 100%; }
      .searchProducts .label-search.small, .side-menu .label-search.small {
        width: 18%; }
      .searchProducts .label-search.large, .side-menu .label-search.large {
        width: 48%; }
      .searchProducts .label-search.xlarge, .side-menu .label-search.xlarge {
        width: 75%; }
    .searchProducts a.btnSearch, .side-menu a.btnSearch {
      width: 48%;
      float: left; }
    .searchProducts a.btnReset, .side-menu a.btnReset {
      width: 48%;
      float: right; }
    .searchProducts a.btnSearch, .searchProducts a.btnReset, .side-menu a.btnSearch, .side-menu a.btnReset {
      margin: 0; }
    .searchProducts .btnSearchProducts, .side-menu .btnSearchProducts {
      margin: 4.5em 0 0; }
    .searchProducts #Refine-normal, .side-menu #Refine-normal {
      display: none; }
    .searchProducts #Refine-mobile, .side-menu #Refine-mobile {
      display: block; }

  .side-menu {
    width: 25%; }

  .productsFoundResults {
    width: 100%;
    margin: 0; }
    .productsFoundResults.second {
      width: 70%; }

  #PageTitle .pageImportantInfo {
    font-size: 0.85em;
    width: 251px;
    height: 148px; }
    #PageTitle .pageImportantInfo img {
      margin-left: 11px;
      margin-top: 12px; }

  .usp-header {
    right: 232px!important;
  }
}

@media (max-width: 980px) {
  .infosTitle__title {margin:auto !important;}
  .side-menu.menu-on-mobile-too {
    display:block;
    width: 100%;
    padding: 2em .9em 2em;
    text-align: center;
  }
  .company-profile .pull-left {
    width: 100% !important;
    float:none !important;
  }
}

@media (min-width: 768px) and (max-width: 980px) {
  .fixed-side {
    display: none; }

  embed, .obj-embed {
    display: none; }

  img.embed {
    display: block; }

  #Categories .box {
    width: 32%;
    margin-right: 2%; }
    #Categories .box.last {
      margin-right: 0; }
    #Categories .box .img {
      max-height: 280px;
      overflow: hidden; }

  #man {
    display: none; }

  .backToSearch {
    display: none; }

  #bottomFooter {
    text-align: center; }
    #bottomFooter .logo {
      float: none;
      margin-left: auto;
      margin-right: auto; }
    #bottomFooter .menu-right {
      float: none;
      width: 100%; }
    #bottomFooter #numTel {
      float: none; }
    #bottomFooter #menuFooter {
      float: none; }

  #Logo {
    display: none; }

  #mobile-logo {
    display: block; }

  #Menu a {
    padding: 0 9px;
    font-size: 0.8em; }

  #Menu {
    display: none; }

  #mobile-top, #mobile-top-phone {
    display: block; }

  #Categories .box {
    width: 100%;
    margin-bottom: 1em; }
    #Categories .box h3 {
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
    #Categories .box .img {
      display: none; }
    #Categories .box .btn {
      display: none; }
    #Categories .box:hover h3, #Categories .box:focus h3 {
      background: #033176; }

  #infosTitle {
    width: 100%;
    text-align: center;
    font-size: 0.9em; }

  #quickSearchBox {
    display: none; }

  #dark {
    background: #222; }

  #Infos {
    background: #ededed;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ededed), color-stop(67%, #FFF));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* IE10+ */
    background: linear-gradient(to bottom, #ededed 0%, #FFF 67%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#FFF',GradientType=0 );
    /* IE6-9 */ }

  #PageTitle {
    background: #ededed;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ededed), color-stop(67%, #FFF));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* IE10+ */
    background: linear-gradient(to bottom, #ededed 0%, #FFF 67%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#FFF',GradientType=0 );
    /* IE6-9 */
    height: inherit; }

  .shareProduct a {
    padding: 0 20px; }
    .shareProduct a p {
      display: none; }

  .sm-col-100 {
    width: 100%;
    padding-right: 0; }

  #map_canvas {
    width: 99.5%;
    height: 180px;
    margin: 1.5em 0; }

  .btnSelling, .btnBuying, input[type="button"], input[type="submit"], .btnUpdate, .FormTable .FormBouton, #dark .btnList {
    font-size: 1em; }

  .FormTable input.FormTextBox, .FormTable input.FormTextBoxError {
    width: 100%;
    margin: 0; }

  #dark .Title {
    font-size: 2.1em; }
  #dark .Text {
    font-size: 1.7em; }

  /*** Search filters **/
  .head-toggle .btn-toggle-menu-filters {
    display: block; }

  #menu-filters {
    display: none; }

  .searchProducts {
    background: #F1F1F1;
    width: 100%;
    padding: 1.5em 1em; }
    .searchProducts .refineSearch {
      margin: 0 -2%;
      padding: 0 2%; }
    .searchProducts .label-search {
      width: 30%;
      margin-left: 1.5%;
      margin-right: 1.5%;
      float: left; }
      .searchProducts .label-search select {
        width: 100%; }
      .searchProducts .label-search.small {
        width: 18%; }
      .searchProducts .label-search.large {
        width: 48%; }
      .searchProducts .label-search.xlarge {
        width: 75%; }
    .searchProducts a.btnSearch {
      width: 48%;
      float: left; }
    .searchProducts a.btnReset {
      width: 48%;
      float: right; }
    .searchProducts a.btnSearch, .searchProducts a.btnReset {
      margin: 0; }
    .searchProducts .btnSearchProducts {
      margin: 4.5em 0 0; }
    .searchProducts #Refine-normal {
      display: none; }
    .searchProducts #Refine-mobile {
      display: block; }

  .side-menu {
    display: none; }

  .productsFoundResults.second {
    margin-top: 0; }

  .signup-btn {
    margin-top: 0;
    margin-bottom: 2em; }

  .productsFoundResults {
    width: 100%;
    margin: 0; }
    .productsFoundResults table {
      font-size: 0.9em; }

  #PageTitle .pageImportantInfo {
    display: none;
    font-size: 0.85em;
    width: 251px;
    height: 148px; }
    #PageTitle .pageImportantInfo img {
      margin-left: 11px;
      margin-top: 12px; }

  .productFound .infoProductFound {
    width: 62%; }

  #Categories .box .label {
    top: -8px;
    right: -8px;
    width: 143px;
    height: 100px; } }
@media (min-width: 500px) and (max-width: 767px) {
  .fixed-side {
    display: none; }

  embed, .obj-embed {
    display: none; }

  img.embed {
    display: block; }

  #man {
    display: none; }

  #Menu {
    display: none; }

  #mobile-top, #mobile-top-phone {
    display: block; }

  .backToSearch {
    display: none; }

  #Logo {
    display: none; }

  #mobile-logo {
    display: block; }

  #Categories .box {
    width: 100%;
    margin-bottom: 1em; }
    #Categories .box h3 {
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
    #Categories .box .img {
      display: none; }
    #Categories .box .btn {
      display: none; }
    #Categories .box:hover h3, #Categories .box:focus h3 {
      background: #033176; }

  #infosTitle {
    width: 100%;
    text-align: center;
    font-size: 0.8em; }

  #quickSearchBox {
    display: none; }

  #dark {
    background: #222; }

  #Infos {
    background: #ededed;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ededed), color-stop(67%, #FFF));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* IE10+ */
    background: linear-gradient(to bottom, #ededed 0%, #FFF 67%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#FFF',GradientType=0 );
    /* IE6-9 */ }

  #PageTitle {
    background: #ededed;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ededed), color-stop(67%, #FFF));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* IE10+ */
    background: linear-gradient(to bottom, #ededed 0%, #FFF 67%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#FFF',GradientType=0 );
    /* IE6-9 */
    height: inherit; }

  #bottomFooter {
    text-align: center;
    height: inherit;
    background: #ffffff;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ffffff 0%, #ededed 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #ededed));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ffffff 0%, #ededed 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ffffff 0%, #ededed 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ffffff 0%, #ededed 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #ffffff 0%, #ededed 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed',GradientType=0 );
    /* IE6-9 */ }
    #bottomFooter .menu-right {
      float: none;
      width: 100%; }
      #bottomFooter .menu-right #numTel {
        float: none;
        margin: 1.5em 0;
        padding: 0; }
    #bottomFooter .logo {
      width: 160px;
      float: none;
      margin-left: auto;
      margin-right: auto; }
    #bottomFooter #copyrights {
      float: none; }
    #bottomFooter #concepteurs {
      float: none;
      margin: 0; }
      #bottomFooter #concepteurs * {
        float: none;
        display: inline-block; }

  #MenuMobile {
    width: 40px;
    height: 22px; }

  #SearchMobile {
    width: 30px;
    height: 29px; }

  #mobile-logo img {
    width: 150px;
    height: 47px;
    margin-bottom: .3em; }

  .pageImportantInfo {
    display: none; }

  .product .detailsProductLeft, .product .detailsProductRight {
    width: 100%; }

  .product .detailsProductTitle {
    width: 50%; }

  #PageTitle h1 {
    font-size: 2em !important;
    margin-top: 35px !important; }

  /*** Product fiche | Plant of the month + Used item ****/
  #PageInfos .product-fiche .img {
    width: 100%; }

  .descriptionProduct {
    width: 100%;
    margin-left: 0;
    border-bottom: 0; }

  .btnProduct {
    text-align: center; }
    .btnProduct a.btnPpt {
      float: none;
      text-align: center;
      display: inline-block;
      margin: 0 7px 14px; }

  /*** Sharing bar ******/
  .shareProduct {
    width: 100%;
    text-align: center;
    margin: 1.5em 0 0;
    padding: 2em 0 0; }
    .shareProduct .productPhone {
      float: none;
      width: 100%;
      display: inline-block;
      margin: 0 auto; }
    .shareProduct .shareIcons {
      float: none;
      display: inline-block;
      margin: 15px auto 30px; }

  .print {
    display: none; }

  .sm-col-100 {
    width: 100%; }

  #map_canvas {
    width: 99.5%;
    height: 180px;
    margin: 1.5em 0; }

  .btnSelling, .btnBuying, input[type="button"], input[type="submit"], .btnUpdate, .FormTable .FormBouton, #dark .btnList {
    font-size: 1em; }

  .FormTable input.FormTextBox, .FormTable input.FormTextBoxError {
    width: 100%;
    margin: 0; }

  #dark .Title {
    font-size: 1.9em; }
  #dark .Text {
    font-size: 1.5em; }

  .col-50 {
    width: 100%; }
    .col-50.sm-col-50 {
      width: 50%; }

  /*** Search filters **/
  .head-toggle .btn-toggle-menu-filters {
    display: block; }

  #menu-filters {
    display: none; }

  .searchProducts {
    background: #F1F1F1;
    width: 100%;
    padding: 1.5em 1em; }
    .searchProducts .refineSearch {
      margin: 0 -2%;
      padding: 0 2%; }
    .searchProducts .label-search {
      width: 47%;
      margin-left: 1.5%;
      margin-right: 1.5%;
      float: left; }
      .searchProducts .label-search.right {
        float: right; }
      .searchProducts .label-search select {
        width: 100%; }
      .searchProducts .label-search.small {
        width: 18%; }
      .searchProducts .label-search.large {
        width: 48%; }
      .searchProducts .label-search.xlarge {
        width: 63%; }
    .searchProducts a.btnSearch {
      width: 48%;
      float: left; }
    .searchProducts a.btnReset {
      width: 48%;
      float: right; }
    .searchProducts a.btnSearch, .searchProducts a.btnReset {
      margin: 0; }
    .searchProducts .btnSearchProducts {
      margin: 4.5em 0 0; }
    .searchProducts #Refine-normal {
      display: none; }
    .searchProducts #Refine-mobile {
      display: block; }

  .side-menu {
    display: none; }

  .productsFoundResults.second {
    margin-top: 0; }

  .signup-btn {
    margin-top: 0;
    margin-bottom: 2em; }

  .productsFoundResults {
    width: 100%;
    margin: 0; }
    .productsFoundResults table {
      font-size: 0.85em; }

  #PageTitle .pageImportantInfo {
    display: none;
    font-size: 0.85em;
    width: 251px;
    height: 148px; }
    #PageTitle .pageImportantInfo img {
      margin-left: 11px;
      margin-top: 12px; }

  .productFound .img {
    width: 50%;
    margin-bottom: 2em; }
  .productFound .infoProductFound {
    width: 100%;
    margin: 0; }

  .productFound .btnProductFound {
    text-align: center;
    float: left; }
    .productFound .btnProductFound a {
      display: inline-block;
      float: none;
      margin: 0 1em 0 0; }

  #BusinessQuote {
    background: #04409a; }
    #BusinessQuote .title {
      padding: 1em; }

  #Categories .box .label {
    top: -8px;
    right: -8px;
    width: 143px;
    height: 100px; } }
@media (max-width: 499px) {
  .fixed-side {
    display: none;
  }

  embed, .obj-embed {
    display: none; }

  img.embed {
    display: block; }

  #BusinessQuote {
    background: #04409a; }
    #BusinessQuote .title {
      padding: 1.6em; }

  #man {
    display: none; }

  #Menu {
    display: none; }

  #mobile-top, #mobile-top-phone {
    display: block; }

  .backToSearch {
    display: none; }

  #Logo {
    display: none; }

  #mobile-logo {
    display: block; }

  #Categories .box {
    width: 100%;
    margin-bottom: 1em; }
    #Categories .box h3 {
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      padding: 0.7em; }
    #Categories .box .img {
      display: none; }
    #Categories .box .btn {
      display: none; }
    #Categories .box:hover h3, #Categories .box:focus h3 {
      background: #033176; }

  #infosTitle {
    width: 100%;
    text-align: center;
    font-size: 0.7em; }

  #quickSearchBox {
    display: none; }

  #dark {
    background: #222; }

  #Infos {
    background: #ededed;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ededed), color-stop(67%, #FFF));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* IE10+ */
    background: linear-gradient(to bottom, #ededed 0%, #FFF 67%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#FFF',GradientType=0 );
    /* IE6-9 */ }

  #PageTitle {
    background: #ededed;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ededed), color-stop(67%, #FFF));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ededed 0%, #FFF 67%);
    /* IE10+ */
    background: linear-gradient(to bottom, #ededed 0%, #FFF 67%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#FFF',GradientType=0 );
    /* IE6-9 */
    height: inherit; }

  #bottomFooter {
    text-align: center;
    height: inherit;
    background: #ffffff;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ffffff 0%, #ededed 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #ededed));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ffffff 0%, #ededed 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ffffff 0%, #ededed 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ffffff 0%, #ededed 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #ffffff 0%, #ededed 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed',GradientType=0 );
    /* IE6-9 */ }
    #bottomFooter .menu-right {
      float: none;
      width: 100%; }
      #bottomFooter .menu-right #numTel {
        float: none;
        margin: 1.5em 0;
        padding: 0; }
    #bottomFooter .logo {
      float: none;
      margin-left: auto;
      margin-right: auto;
      width: 140px; }
    #bottomFooter #copyrights {
      float: none; }
    #bottomFooter #concepteurs {
      float: none;
      margin: 0; }
      #bottomFooter #concepteurs * {
        float: none;
        display: inline-block; }

  #MenuMobile {
    width: 40px;
    height: 22px; }

  #SearchMobile {
    width: 29px;
    height: 29px; }

  #mobile-logo img {
    /*width: 150px;
    height: 47px;
    margin-bottom: .3em;*/
    width: 135px;
    height: 40px;
    margin-bottom: .5em;
    margin-top: .25em; }

  .pageImportantInfo {
    display: none; }

  .product .detailsProductLeft, .product .detailsProductRight {
    width: 100%; }

  .product .detailsProductTitle {
    width: 50%; }

  #PageTitle h1 {
    font-size: 1.4em;
    margin-top: 1em;
    font-weight: 700; }

  #search-bar input[type="search"], #search-bar input[type="text"] {
    margin-left: 1em; }
  #search-bar input[type="button"], #search-bar input[type="submit"] {
    margin-right: 1em; }

  /*** Product fiche | Plant of the month + Used item ****/
  #PageInfos .product-fiche .img {
    width: 100%; }

  .descriptionProduct {
    width: 100%;
    margin-left: 0;
    border-bottom: 0; }

  .btnProduct {
    text-align: center; }
    .btnProduct a.btnPpt {
      float: none;
      text-align: center;
      display: inline-block;
      margin: 0 7px 14px; }

  /*** Sharing bar ******/
  .shareProduct {
    width: 100%;
    text-align: center;
    margin: 1.5em 0 0;
    padding: 2em 0 0; }
    .shareProduct .productPhone {
      float: none;
      width: 100%;
      display: inline-block;
      margin: 0 auto; }
    .shareProduct .shareIcons {
      float: none;
      display: inline-block;
      margin: 15px auto 30px; }
    .shareProduct a {
      padding: 0 15px; }
      .shareProduct a p {
        font-size: 1em; }

  .print {
    display: none; }

  .sm-col-100 {
    width: 100%; }

  #map_canvas {
    width: 99.5%;
    height: 180px;
    margin: 1.5em 0; }

  .btnSelling, .btnBuying, input[type="button"], input[type="submit"], .btnUpdate, .FormTable .FormBouton, #dark .btnList {
    font-size: 0.9em; }

  .FormTable input.FormTextBox, .FormTable input.FormTextBoxError {
    width: 100%;
    margin: 0; }

  #dark .Title {
    font-size: 1.7em; }
  #dark .Text {
    font-size: 1.3em; }

  .col-50 {
    width: 100%; }
    .col-50.col-xsm-50 {
      width: 50%; }

  .row {
    margin-left: -15px;
    margin-right: -15px; }

  /*** Search filters **/
  .head-toggle .btn-toggle-menu-filters {
    display: block; }

  #menu-filters {
    display: none; }

  .searchProducts {
    background: #F1F1F1;
    width: 100%;
    padding: 0.5em 1.2em 0.8em;
    margin-bottom: 1.25em; }
    .searchProducts .refineSearch {
      margin: 0 -2%;
      padding: 0 2%; }
      .searchProducts .refineSearch p {
        margin: 0.85em 0 0.25em; }
    .searchProducts .label-search {
      width: 98.5%;
      margin-left: 1.5%;
      margin-right: 1.5%;
      float: left;
      margin-bottom: 0.5em; }
      .searchProducts .label-search.title {
        margin: 1em 0 0.5em; }
      .searchProducts .label-search select {
        width: 100%; }
      .searchProducts .label-search.small {
        width: 98.5%; }
      .searchProducts .label-search.large {
        width: 48%; }
      .searchProducts .label-search.xlarge {
        width: 97.5%;
        float: right; }
    .searchProducts a.btnSearch {
      width: 48%;
      float: left; }
    .searchProducts a.btnReset {
      width: 48%;
      float: right; }
    .searchProducts a.btnSearch, .searchProducts a.btnReset {
      margin: 0; }
    .searchProducts .btnSearchProducts {
      margin: 0.5em 0 0; }
    .searchProducts #Refine-normal {
      display: none; }
    .searchProducts #Refine-mobile {
      display: block; }

  .side-menu {
    display: none; }

  .productsFoundResults.second {
    margin-top: 0; }

  .signup-btn {
    margin-top: 0;
    margin-bottom: 1.5em;
    display: none; }

  .productsFoundResults {
    width: 100%;
    margin: 0; }
    .productsFoundResults table {
      font-size: 0.85em; }

  #PageTitle .pageImportantInfo {
    display: none;
    font-size: 0.85em;
    width: 251px;
    height: 148px; }
    #PageTitle .pageImportantInfo img {
      margin-left: 11px;
      margin-top: 12px; }

  .productFound .img {
    margin-left: 0;
    width: 100%; }
  .productFound .infoProductFound {
    margin-left: 0;
    margin-top: 2em;
    width: 100%; }

  .selectPage {
    display: none;
    /*float: right;
    select{
      font-size: 80%;
      max-width: 40px;
    }*/ }

  .numberPage {
    float: none;
    text-align: center;
    margin: 1.25em 0 0; }
    .numberPage a {
      width: 28px;
      height: 28px;
      line-height: 26.5px;
      font-size: 0.85em; }

  #mobile-nav ul {
    font-size: 0.9em; }

  .productsFoundResults.second h2 {
    font-size: 1.75em; }
  .productsFoundResults.second h3 {
    font-size: 1.5em; }

  .productFound .btnProductFound {
    text-align: center;
    float: none; }
    .productFound .btnProductFound a {
      display: inline-block;
      float: none;
      margin: 0.5em;
      font-size: 0.75em; }

  #Categories .box .label {
    top: -6px;
    right: -6px;
    width: 113px;
    height: 79px; }

  /** Home page box slider **/
  .currentBestDeals .bxslider > li .img {
    width: 100%;
    text-align: center;
    margin-bottom: 1em; }
    .currentBestDeals .bxslider > li .img img {
      float: none; }
  .currentBestDeals .bxslider > li .info {
    width: 100%;
    margin: 0;
    font-size: 14px; } }
@media (min-width: 1260px) {
  .fixed-side {
    display: block; }

  #Menu a {
    padding: 0px 16px 0px 16px; }

  #man {
    right: -258px; }

  #mobile-nav {
    display: none !important; }

  #search-bar {
    display: none !important; }

  .productsFoundResults {
    width: 76.5%; }

  #Refine-mobile {
    display: none !important; }

  .toogle-search {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    display: block !important; } }


